
import { defineComponent, ref, nextTick, watch, h, unref } from "vue";
import appliance from "@/layout/appliance.vue";
import { useGlobal } from "@/global";
import UploadProcess from "@/components/UploadProcess.vue";
import { ElNotification, ElButton } from "element-plus";
import { i18n } from "@/i18n/index";

export default defineComponent({
    components: {
        // appliance,
        UploadProcess
    },
    setup() {
        const $t = i18n.global.t
        const uploadRef = ref();

        nextTick(() => {
            if (uploadRef.value) {
                useGlobal().uploadProcessRef.value = uploadRef.value;
            }
        });
        const upgrade = () => {
            ElNotification({
                duration: 0,
                title: $t('notification-update-title'),
                message: h("div", { class: "w-full text-left" }, [
                    h('span',{},$t('new-version-alert')),
                    h(
                        ElButton,
                        {
                            size: "mini",
                            type: "primary",
                            onClick(e) {
                                location.reload();
                            }
                        },
                        $t('refresh')
                    )
                ]),
                position: "bottom-right"
            });
        };

        watch(() => useGlobal().App.value?.newVersion, v => {
            if (v) {
                upgrade()
            }
        });

        return {
            uploadRef
        };
    },
    data() {
        let _global = useGlobal();
        return {
            isLogin: _global.isLogin
        };
    },
    watch: {
        isLogin: function(val: boolean) {
            if (!val) {
                this.$router.replace("/login");
            }
        }
    }
});
