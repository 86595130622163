// import _axios from "./axios";
import { ApiRutenType, Unpromise } from "@/@types/Utils.js";
import * as API from "./list/api.js";

import { useGlobal } from "@/global";

/**
 * 代理 API 方法
 * 
 */
class ApiFuncProxy<T extends keyof typeof API> {
    value: typeof API[T];
    constructor(
        apiFuc: typeof API[T],
        option: {
            success?: (res: ApiRutenType<typeof useApi, T>) => void;
            fail?: (err: Error) => void;
        }
    ) {
        this.value = new Proxy(apiFuc, {
            apply(t, thisArg, argArray) {
                return t(argArray[0])
                    .then((res:any) => {
                        if (option.success) {
                            const _s = option.success;
                            _s(res.data);
                        }

                        return res
                    })
                    .catch((err:any) => {
                        console.error(err);
                        if (option.fail) {
                            const _f = option.fail;
                            _f(Object.assign({}, err));
                        }
                        // console.log('Proxy err', err)

                        return err
                    });
            }
        });
    }
}

const apiListAuto = Object.assign({}, API);

/**
 * 添加 “查询系统支持的媒体类型信息” 接口副作用
 */
apiListAuto.getCorpMediaSupports = new ApiFuncProxy<"getCorpMediaSupports">(
    API["getCorpMediaSupports"],
    {
        success: res => {
            let _suffix = "";
            res.rows.forEach((item)=>{
              if(item.category == 3) {
                _suffix += `\.${item.suffix}|`
              }
            })
            
            _suffix = _suffix.replace(/\|$/, "");
            useGlobal().isMusicReg.value = new RegExp(`(${_suffix})$`);
        }
    }
).value;

function useApi() {
    return apiListAuto;
}

export default useApi;
