/**
 * Mooncell Cloud McApp 管理
 */

import router, { addPathLastSlash, redirectAct, MenuRoute } from "@/router";
import common from "@/layout/common.vue";
import { ApiRutenType, ObjKey } from "./@types/Utils";
import useApi from "./api/apiList";
import { GlobalConst, useGlobal } from "./global";
import { RouteRecordRaw } from "vue-router";
import { getLogin, getUserAuth, getUserInfo } from "./utils/getLoginState";
import { MC_APP_LIST } from "@/McAppList";

type UserApp = ObjKey<ApiRutenType<typeof useApi, "getCorpUserAuthInfo">, "apps">;

export type MCAPP_LAYOUT = "default" | "custom";

export interface ADDMCAPP {
    /**
     * 应用在云平台上需要调用的布局参数
     *
     * **default** : 带有顶部云平台顶部栏
     *
     * **custom** : 全白页面
     */
    layout?: MCAPP_LAYOUT;
    /**
     * 应用路由
     */
    routes: MenuRoute;
}

export interface INSTALLADDMCAPP {
    default: {
        app: ADDMCAPP;
    };
}

export type InstallAPP = {
    /**
     * 后台定义的McApp代号
     *
     * _每次添加新应用需要先从后台要一个App唯一代号_
     */
    name: string;
    /**
     * 安装函数
     *
     * 示例:
     * ```typescript
     * install: () => import(\/\* webpackChunkName: "app_standard" \*\/ "@/package/App/standard/app")
     * ```
     */
    install: () => Promise<any>;
};

class McApp {
    readonly id: string;
    layout: MCAPP_LAYOUT;
    routes: MenuRoute;
    rootPath: string;
    constructor(id: string, op: ADDMCAPP) {
        this.id = id;
        this.layout = op.layout ?? "default";
        this.routes = op.routes;
        this.rootPath = "";
    }
}

const createAppRouter = (router: RouteRecordRaw[]): RouteRecordRaw => {
    // 用平台应用目录
    return {
        path: GlobalConst.APP_PATH_ROOT,
        component: () =>
            import(/* webpackChunkName: baseApplianceLayout */ "@/layout/appliance.vue"),
        children: router,
    };
};

export class McAppManage {
    readonly apps = MC_APP_LIST;
    readonly installApps: McApp[] = [];
    promise?: Promise<McAppManage>;
    install(userApp: UserApp) {
        this.promise = new Promise(async (res, rej) => {
            const _install: { id: string; app: InstallAPP }[] = [];
            this.apps.forEach((app) => {
                userApp.forEach((uapp) => {
                    if (uapp.name === app.name) {
                        _install.push({
                            id: uapp.id,
                            app: app,
                        });
                    }
                });
            });

            try {
                const readyMcApp = await Promise.all(_install.map(({ id, app }) => app.install()));
                const routes: MenuRoute[] = [];
                console.log("_install list", _install.length);
                _install.forEach(({ id }, index) => {
                    const mcApp = readyMcApp[index];
                    const app = new McApp(id, mcApp.default.app);
                    routes.push(app.routes);
                    app.rootPath = `${GlobalConst.APP_PATH_ROOT}/${app.routes.path}`;
                    this.installApps.push(app);
                });

                const sysRoot = router
                    .getRoutes()
                    .filter((r) => r.path === GlobalConst.APP_PATH_ROOT && r.children.length > 1);

                // console.log('routes', sysRoot[0].children.concat(routes));
                router.addRoute(createAppRouter(sysRoot[0].children.concat(routes)));

                // console.log("vue router", router, router.getRoutes().length);
            } catch (error) {
                console.log("McApp install fail", error);

                rej(this);
            }

            res(this);
        });

        return this.promise;
    }
}

function createMcAppMaagne() {
    return new McAppManage();
}

/**
 * 初始化云平台
 * @param perms 权限列表
 * @param apps 应用列表
 */
export function initMcCloud(perms: string[], apps: UserApp) {
    // 缓存权限列表
    const PermsMap = new Map();
    perms.forEach((i) => {
        PermsMap.set(i, i);
    });
    const myMap = JSON.stringify(Array.from(PermsMap.entries()));
    localStorage.setItem("userPower", myMap);
    useGlobal().userPowerInfo.value = PermsMap;
    getLogin();
    getUserInfo();
    getUserAuth();

    // 创建McApp管理，安装McApp
    const appManage = createMcAppMaagne();
    useGlobal().McAppManage.value = appManage;
    return appManage.install(apps);
}

/**
 * McApp 安装器
 * @param app 应用参数
 * @returns
 */
export function installMcApp(app: ADDMCAPP) {
    return {
        app: app,
    };
}
