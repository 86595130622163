
import { defineComponent, PropType } from "vue"
import { IconName } from '@/@types/IconName'

export default defineComponent({
    props: {
        mode: {
         type: String as PropType<'svg' | 'font'>,
         required: false,
         default: 'svg',
        },
        type: {
            type: String as PropType<IconName>,
            required: true
        }
    },
    setup(){
      return {}
    }
});
