
    /* eslint-disable */
    import api from "../axios";
    const parseBol = (b) => {
      return typeof b == 'string' ? !(/^(false|0)$/i).test(b) && !!b : b;
    }
    const host = `${ (parseBol(process.env.VUE_APP_ISMOCK) ? process.env.VUE_APP_MOCKURL : process.env.VUE_APP_BACKSERVERURL
      )}`
    
      function getCorpTeamTree({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/team/tree`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpTeamDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/team/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpTeamCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/team/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpTeamUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/team/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpPlayerAuthInfo({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/player/auth/info`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserCaptchaVerify({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/captcha/verify`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserCaptchaRequest({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/captcha/request`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserLogList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/log/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserKeyRequest({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/key/request`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserChangeTeam({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/change/team`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpUserMobileBounded({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/user/mobile/bounded`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpUserEmailBounded({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/user/email/bounded`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserBatchdelete({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/batch-delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserBatchenable({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/batch-enable`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpUserOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/user/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserPasswdChange({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/passwd/change`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserBoundMobile({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/bound/mobile`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserBoundEmail({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/bound/email`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserReset({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/reset`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserAssignedRolesGet({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/assigned/roles/get`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserAssignedRolesSet({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/assigned/roles/set`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpUserRolePermissions({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/user/role/permissions`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserRolePermissions({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/role/permissions`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserRoleDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/role/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserRoleBatchdelete({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/role/batch-delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserRoleList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/role/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserRoleCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/role/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserRoleUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/role/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpUserRoleOptions({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/user/role/options`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserAuthFindback({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/auth/findback`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserAuthRegister({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/auth/register`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpUserAuthInfo({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/user/auth/info`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserAuthLogout({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/auth/logout`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserAuthLogin({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/auth/login`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpUserConfigGet({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/user/config/get`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserConfigSet({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/config/set`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUserVerifycodeRequest({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/user/verify-code/request`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpPropOne({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/prop/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpNoticeList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/notice/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getHealthCheck({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/health/check`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCredentialsList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/credentials/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCredentialsDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/credentials/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCredentialsCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/credentials/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCredentialsUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/credentials/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCredentialsOptions({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/credentials/options`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpMediaDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/media/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpMediaList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/media/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpMediaBatchdelete({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/media/batch-delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpMediaChangeTeam({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/media/change/team`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpMediaOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/media/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpMediaAvailable({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/media/available`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpMediaSupport({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/media/support`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpMediaExists({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/media/exists`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpMediaSupports({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/media/supports`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpMediaCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/media/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpMediaUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/media/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpControllogsList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/control-logs/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postPlatformsDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/platforms/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postPlatformsList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/platforms/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getPlatformsOptions({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/platforms/options`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postPlatformsCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/platforms/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postPlatformsUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/platforms/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postBasicMobilecodeList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/basic/mobile-code/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpPlayergroupDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/player-group/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpPlayergroupTree({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/player-group/tree`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpPlayergroupCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/player-group/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpPlayergroupUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/player-group/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpPlaylogsList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/play-logs/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postUserDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/user/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postUserCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/user/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postUserList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/user/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postUserUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/user/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getUserItem({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/user/item`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postNoticeDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/notice/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postNoticeBatchdelete({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/notice/batch-delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postNoticeCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/notice/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postNoticeUserDelete({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/notice/user/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postNoticeUserList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/notice/user/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getNoticeUserCat({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/notice/user/cat`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postNoticeUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/notice/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postNoticeList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/notice/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getNoticeUserNnotices({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/notice/user/nnotices`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postNoticeUserTouch({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/notice/user/touch`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminAuthInfo({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/auth/info`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminAuthLogout({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/auth/logout`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminAuthProfilePassword({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/auth/profile/password`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminAuthLogin({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/auth/login`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postApplicationsDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/applications/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postApplicationsList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/applications/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postApplicationsCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/applications/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postApplicationsUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/applications/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminlogList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin-log/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postSystemconfigDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/system-config/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postSystemconfigCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/system-config/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postSystemconfigList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/system-config/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postSystemconfigUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/system-config/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getSystemconfigItem({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/system-config/item`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getRolePermissions({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/role/permissions`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postRolePermissions({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/role/permissions`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postRoleDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/role/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postRoleCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/role/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postRoleUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/role/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postRoleList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/role/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getRoleOptions({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/role/options`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpDeviceLedOverview({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/device/led/overview`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpDeviceLedDownloadcert({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/device/led/download-cert`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpDeviceDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/device/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpDeviceList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/device/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpDeviceChangeGroup({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/device/change/group`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpDeviceChangeTeam({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/device/change/team`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpDeviceOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/device/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpDeviceOnlineOverview({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/device/online/overview`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpDeviceViewSnap({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/device/view/snap`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpDeviceLedCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/device/led/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpDeviceCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/device/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpDeviceUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/device/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpDeviceLedUnbound({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/device/led/unbound`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getDashboardTotals({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/dashboard/totals`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpOssImm({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/oss/imm`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpOssAuth({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/oss/auth`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAccesskeysList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/access-keys/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAccesskeysDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/access-keys/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAccesskeysCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/access-keys/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAccesskeysUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/access-keys/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postStorageDelete({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/storage/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postStorageList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/storage/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postStorageUpload({
    
    
    req_body_form,
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/storage/upload`,
            headers: {"Content-Type":"multipart/form-data","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          req_body_form,
          req_body_other,
        )
      
          })
      }
    
      function getStorageDownloadFilename({
    
    
    
    req_body_other,
    req_params,
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/storage/download/{filename}`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postStorageBucketCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/storage/bucket/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postStorageUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/storage/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postStorageUploadUrl({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/storage/upload/url`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postStorageDownloadUrl({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/storage/download/url`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postBuildsDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/builds/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postBuildsCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/builds/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postBuildsSubmitJob({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/builds/submit/job`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postBuildsList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/builds/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getBuildsViewJob({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/builds/view/job`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postBuildsUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/builds/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpDevicectlCmdV1({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/device-ctl/cmd/v1`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpDevicectlSnap({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/device-ctl/snap`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpDevicectlUpgradesw({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/device-ctl/upgrade-sw`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpDevicectlQueryCtlret({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/device-ctl/query/ctl-ret`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpDevicectlQuery({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/device-ctl/query`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postDeploysDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/deploys/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postDeploysCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/deploys/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postDeploysUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/deploys/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postDeploysList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/deploys/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postStagesDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/stages/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postStagesCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/stages/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postStagesUpsert({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/stages/upsert`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postStagesUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/stages/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postStagesList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/stages/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postStagesDeploy({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/stages/deploy`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postProjectsDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/projects/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postProjectsCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/projects/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postProjectsUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/projects/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getProjectsOptions({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/projects/options`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getProjectsBranches({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/projects/branches`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postProjectsList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/projects/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postV1EqDown({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/v1/eq/down`,
            headers: {"Content-Type":"application/json","Authorization":"undefined","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postV1Sign({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/v1/sign`,
            headers: {"Content-Type":"application/json","Authorization":"undefined","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getV1TextassetFilename({
    
    
    
    req_body_other,
    req_params,
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/v1/text-asset/{filename}`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postV1Upload({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/v1/upload`,
            headers: {"Content-Type":"application/json","Authorization":"undefined","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postV1Weather({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/v1/weather`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postV1Heartbeat({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/v1/heartbeat`,
            headers: {"Content-Type":"application/json","Authorization":"undefined","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpProgramTasksEdit({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/program/tasks/edit`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpProgramDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/program/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpProgramDistribute({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/program/distribute`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpProgramPrecheck({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/program/pre-check`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpProgramBatchdelete({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/program/batch-delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpProgramChangeTeam({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/program/change/team`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpProgramOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/program/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpProgramTasksList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/program/tasks/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpProgramMaxlimits({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/program/max-limits`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpProgramCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/program/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpProgramUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/program/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpProgramList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/program/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpPlanList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/plan/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpPlanDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/plan/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpPlanDistribute({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/plan/distribute`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpPlanPrecheck({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/plan/pre-check`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpPlanBatchdelete({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/plan/batch-delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpPlanChangeTeam({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/plan/change/team`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpPlanOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/plan/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpPlanCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/plan/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpPlanUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/plan/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminMediasupportDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/media-support/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminMediasupportList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/media-support/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminMediasupportOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/media-support/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminMediasupportCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/media-support/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminMediasupportUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/media-support/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminOssAuth({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/oss/auth`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminKeyRequest({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/key/request`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminProductDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/product/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminProductCodecsupportUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/product/codec-support/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminProductOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/product/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminProductList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/product/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminProductCodecsupportQuery({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/product/codec-support/query`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminProductOptions({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/product/options`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminProductCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/product/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminProductEnable({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/product/enable`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminProductUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/product/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminCorpTeamTree({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/corp/team/tree`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminCorpOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/corp/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpCtllogList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/ctl-log/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpTxtassetList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/txt-asset/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminAdminuserloggerList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/admin-user-logger/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpRoleList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/role/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpPlanList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/plan/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpDeviceList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/device/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminCorpPropOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/corp/prop/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpUservcloggerList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/user-vc-logger/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpUserList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/user/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpUserloggerList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/user-logger/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpUserloginLogsList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/user-login/logs/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpDeviceCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/device/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminCorpEnable({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/corp/enable`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpPropUpsert({
    
    
    
    
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/prop/upsert`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          
        )
      
          })
      }
    
      function getAdminCorpRolePermissions({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/corp/role/permissions`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpConfigapps({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/config-apps`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminFirmwareDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/firmware/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminSwupgradeDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/sw-upgrade/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminFirmwareExists({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/firmware/exists`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminSwupgradeExists({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/sw-upgrade/exists`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminFirmwareOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/firmware/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminSwupgradeOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/sw-upgrade/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminFirmwareList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/firmware/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminSwupgradeList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/sw-upgrade/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminSwupgradeApkinfo({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/sw-upgrade/apk-info`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminFirmwareCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/firmware/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminSwupgradeCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/sw-upgrade/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminFirmwareEnable({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/firmware/enable`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminSwupgradeEnable({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/sw-upgrade/enable`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminFirmwareUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/firmware/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminSwupgradeUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/sw-upgrade/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminCodecsupportOptions({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/codec-support/options`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCodecsupportDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/codec-support/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminCodecsupportOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/codec-support/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCodecsupportList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/codec-support/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCodecsupportCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/codec-support/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminCodecsupportEnable({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/codec-support/enable`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCodecsupportUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/codec-support/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpPrecheckCheck({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/pre-check/check`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpNoticeDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/notice/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminCorpNoticeOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/corp/notice/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpNoticeList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/notice/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpNoticeCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/notice/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpNoticeUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/notice/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminCorpDashboardTotals({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/corp/dashboard/totals`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpGeoSearch({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/geo/search`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpWeatherQuery({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/weather/query`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminSmsareaList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/sms-area/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminSmsareaOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/sms-area/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminSmsareaEnable({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/sms-area/enable`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpMapWebak({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/map/web-ak`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getBasicCustomInfo({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/basic/custom/info`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCardReport({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/card/report`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCardHeartbeat({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/card/heartbeat`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getLedReport({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/led/report`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getLedHeartbeat({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/led/heartbeat`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpCloudEncoderDevicectlCmdV1({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/cloud/encoder/device-ctl/cmd/v1`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpCloudEncoderDevicectlQueryCtlret({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/cloud/encoder/device-ctl/query/ctl-ret`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpCloudEncoderDevicectlQuery({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/cloud/encoder/device-ctl/query`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpCloudEncoderLedOverview({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/cloud/encoder/led/overview`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpCloudEncoderLedDownloadcert({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/cloud/encoder/led/download-cert`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpCloudEncoderList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/cloud/encoder/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpCloudEncoderChangeGroup({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/cloud/encoder/change/group`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpCloudEncoderChangeTeam({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/cloud/encoder/change/team`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpCloudEncoderOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/cloud/encoder/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpCloudEncoderLedCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/cloud/encoder/led/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpCloudEncoderUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/cloud/encoder/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpCloudEncoderLedUnbound({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/cloud/encoder/led/unbound`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpCloudEncoderPlayergroupDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/cloud/encoder/player-group/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpCloudEncoderPlayergroupCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/cloud/encoder/player-group/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpCloudEncoderPlayergroupUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/cloud/encoder/player-group/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getCorpCloudEncoderPlayergroupTree({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/corp/cloud/encoder/player-group/tree`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postCorpCloudEncoderControllogsList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/corp/cloud/encoder/control-logs/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminMediacleanDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/mediaClean/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminMediacleanArrange({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/mediaClean/arrange`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminMediacleanBatchdelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/mediaClean/batch-delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminMediacleanList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/mediaClean/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminKtvMediagroupDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/ktv/media-group/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminKtvMediagroupOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/ktv/media-group/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminKtvMediagroupList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/ktv/media-group/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminKtvMediagroupCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/ktv/media-group/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminKtvMediagroupUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/ktv/media-group/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminKtvMediaDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/ktv/media/delete`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminKtvMediaList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/ktv/media/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminKtvMediaExists({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/ktv/media/exists`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminKtvMediaOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/ktv/media/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminKtvMediaCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/ktv/media/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminKtvMediaUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/ktv/media/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminKtvMediaRethumb({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/ktv/media/re-thumb`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAppktvMediagroupList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/app_ktv/media-group/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAppktvMediaList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/app_ktv/media/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAppktvUserAuthPrecheck({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/app_ktv/user/auth/preCheck`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAppktvUserAuthRegister({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/app_ktv/user/auth/register`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAppktvUserAuthRegister1654933229513({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/app_ktv/user/auth/register_1654933229513`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAppktvUserAuthFindback({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/app_ktv/user/auth/findback`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAppktvUserAuthRequestkey({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/app_ktv/user/auth/requestKey`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAppktvUserAuthLogout({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/app_ktv/user/auth/logout`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAppktvUserAuthRequestverifycode({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/app_ktv/user/auth/requestVerifyCode`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAppktvUserAuthLogin({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/app_ktv/user/auth/login`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAppktvFlowusageReport({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/app_ktv/flow-usage/report`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAppktvFlowusageDetail({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/app_ktv/flow-usage/detail`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAppktvFlowusageDownloadrequest({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/app_ktv/flow-usage/download-request`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAppktvUserSubmitactivationinfo({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/app_ktv/user/submitActivationInfo`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminKtvDashboardTotals({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/ktv/dashboard/totals`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminKtvUserUpdatetraffic({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/ktv/user/update-traffic`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminKtvUserOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/ktv/user/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminKtvUserList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/ktv/user/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminKtvUserLoglist({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/ktv/user/log-list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminKtvUserTrafficlist({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/ktv/user/traffic-list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminKtvUserActivate({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/ktv/user/activate`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminKtvUserEnable({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/ktv/user/enable`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminKtvUserDetail({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/ktv/user/detail`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAppktvSignature({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/app-ktv/signature`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAppktvSignres({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/app-ktv/sign-res`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminAuditMedialist({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/audit/media-list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminAuditMedia({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/audit/media`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminAuditText({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/audit/text`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminAuditProgram({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/audit/program`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminAuditTextlist({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/audit/text-list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminAuditProgramlist({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/audit/program-list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpMediaList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/media/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpProgramListtask({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/program/list-task`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpProgramList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/program/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpProgramRelatedmedia({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/program/related-media`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminCorpProgramRelatedtextasset({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/corp/program/related-textAsset`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAppsUpgradeLatestversion({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/apps/upgrade/latest-version`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminAppsUpgradeDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/apps/upgrade/delete`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminAppsUpgradeOne({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/apps/upgrade/one`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminAppsUpgradeList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/apps/upgrade/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminAppsUpgradeExists({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/apps/upgrade/exists`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminAppsUpgradeCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/apps/upgrade/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminAppsUpgradeEnable({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/apps/upgrade/enable`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminAppsUpgradeUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/apps/upgrade/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminAppsList({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/apps/list`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function getAdminAppsDelete({
    req_query,
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'get',
            url: `${host}/mc-api/v2/admin/apps/delete`,
            headers: {"token": localStorage.getItem('token'),},
            
        params: req_query,
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminAppsCreate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/apps/create`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    
      function postAdminAppsUpdate({
    
    
    
    req_body_other,
    
  }) {
          
          return api({
            method: 'post',
            url: `${host}/mc-api/v2/admin/apps/update`,
            headers: {"Content-Type":"application/json","token": localStorage.getItem('token'),},
            
        params: {},
        data: Object.assign({},
          
          req_body_other,
        )
      
          })
      }
    

    export {
      
	getCorpTeamTree,
	postCorpTeamDelete,
	postCorpTeamCreate,
	postCorpTeamUpdate,
	getCorpPlayerAuthInfo,
	postCorpUserCaptchaVerify,
	postCorpUserCaptchaRequest,
	postCorpUserLogList,
	postCorpUserKeyRequest,
	postCorpUserChangeTeam,
	postCorpUserDelete,
	getCorpUserMobileBounded,
	getCorpUserEmailBounded,
	postCorpUserBatchdelete,
	postCorpUserBatchenable,
	getCorpUserOne,
	postCorpUserList,
	postCorpUserCreate,
	postCorpUserPasswdChange,
	postCorpUserBoundMobile,
	postCorpUserBoundEmail,
	postCorpUserReset,
	postCorpUserUpdate,
	postCorpUserAssignedRolesGet,
	postCorpUserAssignedRolesSet,
	getCorpUserRolePermissions,
	postCorpUserRolePermissions,
	postCorpUserRoleDelete,
	postCorpUserRoleBatchdelete,
	postCorpUserRoleList,
	postCorpUserRoleCreate,
	postCorpUserRoleUpdate,
	getCorpUserRoleOptions,
	postCorpUserAuthFindback,
	postCorpUserAuthRegister,
	getCorpUserAuthInfo,
	postCorpUserAuthLogout,
	postCorpUserAuthLogin,
	getCorpUserConfigGet,
	postCorpUserConfigSet,
	postCorpUserVerifycodeRequest,
	postCorpDelete,
	getCorpOne,
	postCorpList,
	getCorpPropOne,
	postCorpCreate,
	postCorpUpdate,
	postCorpNoticeList,
	getHealthCheck,
	postCredentialsList,
	postCredentialsDelete,
	postCredentialsCreate,
	postCredentialsUpdate,
	getCredentialsOptions,
	postCorpMediaDelete,
	postCorpMediaList,
	postCorpMediaBatchdelete,
	postCorpMediaChangeTeam,
	getCorpMediaOne,
	getCorpMediaAvailable,
	getCorpMediaSupport,
	getCorpMediaExists,
	getCorpMediaSupports,
	postCorpMediaCreate,
	postCorpMediaUpdate,
	postCorpControllogsList,
	postPlatformsDelete,
	postPlatformsList,
	getPlatformsOptions,
	postPlatformsCreate,
	postPlatformsUpdate,
	postBasicMobilecodeList,
	postCorpPlayergroupDelete,
	getCorpPlayergroupTree,
	postCorpPlayergroupCreate,
	postCorpPlayergroupUpdate,
	postCorpPlaylogsList,
	postUserDelete,
	postUserCreate,
	postUserList,
	postUserUpdate,
	getUserItem,
	postNoticeDelete,
	postNoticeBatchdelete,
	postNoticeCreate,
	postNoticeUserDelete,
	postNoticeUserList,
	getNoticeUserCat,
	postNoticeUpdate,
	postNoticeList,
	getNoticeUserNnotices,
	postNoticeUserTouch,
	postAdminDelete,
	postAdminList,
	postAdminCreate,
	postAdminUpdate,
	getAdminAuthInfo,
	postAdminAuthLogout,
	postAdminAuthProfilePassword,
	postAdminAuthLogin,
	postApplicationsDelete,
	postApplicationsList,
	postApplicationsCreate,
	postApplicationsUpdate,
	postAdminlogList,
	postSystemconfigDelete,
	postSystemconfigCreate,
	postSystemconfigList,
	postSystemconfigUpdate,
	getSystemconfigItem,
	getRolePermissions,
	postRolePermissions,
	postRoleDelete,
	postRoleCreate,
	postRoleUpdate,
	postRoleList,
	getRoleOptions,
	getCorpDeviceLedOverview,
	getCorpDeviceLedDownloadcert,
	postCorpDeviceDelete,
	postCorpDeviceList,
	postCorpDeviceChangeGroup,
	postCorpDeviceChangeTeam,
	getCorpDeviceOne,
	getCorpDeviceOnlineOverview,
	postCorpDeviceViewSnap,
	postCorpDeviceLedCreate,
	postCorpDeviceCreate,
	postCorpDeviceUpdate,
	postCorpDeviceLedUnbound,
	getDashboardTotals,
	getCorpOssImm,
	getCorpOssAuth,
	postAccesskeysList,
	postAccesskeysDelete,
	postAccesskeysCreate,
	postAccesskeysUpdate,
	postStorageDelete,
	postStorageList,
	postStorageUpload,
	getStorageDownloadFilename,
	postStorageBucketCreate,
	postStorageUpdate,
	postStorageUploadUrl,
	postStorageDownloadUrl,
	postBuildsDelete,
	postBuildsCreate,
	postBuildsSubmitJob,
	postBuildsList,
	getBuildsViewJob,
	postBuildsUpdate,
	postCorpDevicectlCmdV1,
	postCorpDevicectlSnap,
	postCorpDevicectlUpgradesw,
	postCorpDevicectlQueryCtlret,
	postCorpDevicectlQuery,
	postDeploysDelete,
	postDeploysCreate,
	postDeploysUpdate,
	postDeploysList,
	postStagesDelete,
	postStagesCreate,
	postStagesUpsert,
	postStagesUpdate,
	postStagesList,
	postStagesDeploy,
	postProjectsDelete,
	postProjectsCreate,
	postProjectsUpdate,
	getProjectsOptions,
	getProjectsBranches,
	postProjectsList,
	postV1EqDown,
	postV1Sign,
	getV1TextassetFilename,
	postV1Upload,
	postV1Weather,
	postV1Heartbeat,
	postCorpProgramTasksEdit,
	postCorpProgramDelete,
	postCorpProgramDistribute,
	postCorpProgramPrecheck,
	postCorpProgramBatchdelete,
	postCorpProgramChangeTeam,
	getCorpProgramOne,
	postCorpProgramTasksList,
	getCorpProgramMaxlimits,
	postCorpProgramCreate,
	postCorpProgramUpdate,
	postCorpProgramList,
	postCorpPlanList,
	postCorpPlanDelete,
	postCorpPlanDistribute,
	postCorpPlanPrecheck,
	postCorpPlanBatchdelete,
	postCorpPlanChangeTeam,
	getCorpPlanOne,
	postCorpPlanCreate,
	postCorpPlanUpdate,
	postAdminMediasupportDelete,
	postAdminMediasupportList,
	getAdminMediasupportOne,
	postAdminMediasupportCreate,
	postAdminMediasupportUpdate,
	getAdminOssAuth,
	postAdminKeyRequest,
	postAdminProductDelete,
	postAdminProductCodecsupportUpdate,
	getAdminProductOne,
	postAdminProductList,
	getAdminProductCodecsupportQuery,
	getAdminProductOptions,
	postAdminProductCreate,
	getAdminProductEnable,
	postAdminProductUpdate,
	getAdminCorpTeamTree,
	postAdminCorpDelete,
	getAdminCorpOne,
	postAdminCorpList,
	postAdminCorpCtllogList,
	postAdminCorpTxtassetList,
	postAdminAdminuserloggerList,
	postAdminCorpRoleList,
	postAdminCorpPlanList,
	postAdminCorpDeviceList,
	getAdminCorpPropOne,
	postAdminCorpUservcloggerList,
	postAdminCorpUserList,
	postAdminCorpUserloggerList,
	postAdminCorpUserloginLogsList,
	postAdminCorpCreate,
	postAdminCorpDeviceCreate,
	getAdminCorpEnable,
	postAdminCorpUpdate,
	postAdminCorpPropUpsert,
	getAdminCorpRolePermissions,
	postAdminCorpConfigapps,
	postAdminFirmwareDelete,
	postAdminSwupgradeDelete,
	getAdminFirmwareExists,
	getAdminSwupgradeExists,
	getAdminFirmwareOne,
	getAdminSwupgradeOne,
	postAdminFirmwareList,
	postAdminSwupgradeList,
	getAdminSwupgradeApkinfo,
	postAdminFirmwareCreate,
	postAdminSwupgradeCreate,
	getAdminFirmwareEnable,
	getAdminSwupgradeEnable,
	postAdminFirmwareUpdate,
	postAdminSwupgradeUpdate,
	getAdminCodecsupportOptions,
	postAdminCodecsupportDelete,
	getAdminCodecsupportOne,
	postAdminCodecsupportList,
	postAdminCodecsupportCreate,
	getAdminCodecsupportEnable,
	postAdminCodecsupportUpdate,
	getCorpPrecheckCheck,
	postAdminCorpNoticeDelete,
	getAdminCorpNoticeOne,
	postAdminCorpNoticeList,
	postAdminCorpNoticeCreate,
	postAdminCorpNoticeUpdate,
	getAdminCorpDashboardTotals,
	getCorpGeoSearch,
	postCorpWeatherQuery,
	postAdminSmsareaList,
	getAdminSmsareaOne,
	getAdminSmsareaEnable,
	getCorpMapWebak,
	getBasicCustomInfo,
	getCardReport,
	getCardHeartbeat,
	getLedReport,
	getLedHeartbeat,
	postCorpCloudEncoderDevicectlCmdV1,
	postCorpCloudEncoderDevicectlQueryCtlret,
	postCorpCloudEncoderDevicectlQuery,
	getCorpCloudEncoderLedOverview,
	getCorpCloudEncoderLedDownloadcert,
	postCorpCloudEncoderList,
	postCorpCloudEncoderChangeGroup,
	postCorpCloudEncoderChangeTeam,
	getCorpCloudEncoderOne,
	postCorpCloudEncoderLedCreate,
	postCorpCloudEncoderUpdate,
	postCorpCloudEncoderLedUnbound,
	postCorpCloudEncoderPlayergroupDelete,
	postCorpCloudEncoderPlayergroupCreate,
	postCorpCloudEncoderPlayergroupUpdate,
	getCorpCloudEncoderPlayergroupTree,
	postCorpCloudEncoderControllogsList,
	postAdminMediacleanDelete,
	getAdminMediacleanArrange,
	postAdminMediacleanBatchdelete,
	getAdminMediacleanList,
	postAdminKtvMediagroupDelete,
	getAdminKtvMediagroupOne,
	postAdminKtvMediagroupList,
	postAdminKtvMediagroupCreate,
	postAdminKtvMediagroupUpdate,
	postAdminKtvMediaDelete,
	postAdminKtvMediaList,
	getAdminKtvMediaExists,
	getAdminKtvMediaOne,
	postAdminKtvMediaCreate,
	postAdminKtvMediaUpdate,
	getAdminKtvMediaRethumb,
	postAppktvMediagroupList,
	postAppktvMediaList,
	getAppktvUserAuthPrecheck,
	postAppktvUserAuthRegister,
	postAppktvUserAuthRegister1654933229513,
	postAppktvUserAuthFindback,
	postAppktvUserAuthRequestkey,
	postAppktvUserAuthLogout,
	postAppktvUserAuthRequestverifycode,
	postAppktvUserAuthLogin,
	postAppktvFlowusageReport,
	getAppktvFlowusageDetail,
	postAppktvFlowusageDownloadrequest,
	postAppktvUserSubmitactivationinfo,
	getAdminKtvDashboardTotals,
	getAdminKtvUserUpdatetraffic,
	getAdminKtvUserOne,
	postAdminKtvUserList,
	postAdminKtvUserLoglist,
	postAdminKtvUserTrafficlist,
	getAdminKtvUserActivate,
	getAdminKtvUserEnable,
	getAdminKtvUserDetail,
	getAppktvSignature,
	postAppktvSignres,
	postAdminAuditMedialist,
	postAdminAuditMedia,
	postAdminAuditText,
	postAdminAuditProgram,
	postAdminAuditTextlist,
	postAdminAuditProgramlist,
	postAdminCorpMediaList,
	postAdminCorpProgramListtask,
	postAdminCorpProgramList,
	postAdminCorpProgramRelatedmedia,
	postAdminCorpProgramRelatedtextasset,
	postAppsUpgradeLatestversion,
	getAdminAppsUpgradeDelete,
	getAdminAppsUpgradeOne,
	postAdminAppsUpgradeList,
	getAdminAppsUpgradeExists,
	postAdminAppsUpgradeCreate,
	getAdminAppsUpgradeEnable,
	postAdminAppsUpgradeUpdate,
	postAdminAppsList,
	getAdminAppsDelete,
	postAdminAppsCreate,
	postAdminAppsUpdate,

    }
  