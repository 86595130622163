type UserAgent = typeof navigator.userAgent;
type Engine = "WebKit" | "Trident" | "Gecko" | "Presto";
type BrowserApp =  "Chrome" |
    "IE" |
    "Firefox" |
    "Opera" |
    "Safari" |
    "UC" |
    "QQ" |
    "BaiDu" |
    "Maxthon" |
    "SouGou" |
    "LBBROWSER" |
    "Wechat" |
    "Edge"

type SystemOS = 
    "Windows" |
    "Linux" |
    "Mac" |
    "Android" |
    "iOS" |
    "iPhone" |
    "iPad" |
    "WP" |
    "BlackBerry" |
    "MeeGo" |
    "Symbian"
type Device = 'PC' | 'Mobile' | 'Tablet'
export class Browser {
    u = navigator.userAgent;
    readonly match = {
        //The kernel
        Trident: this.u.indexOf("Trident") > 0 || this.u.indexOf("NET CLR") > 0,
        Presto: this.u.indexOf("Presto") > 0,
        WebKit: this.u.indexOf("AppleWebKit") > 0,
        Gecko: this.u.indexOf("Gecko/") > 0,
        //The browser
        UC: this.u.indexOf("UC") > 0 || this.u.indexOf(" UBrowser") > 0,
        QQ: this.u.indexOf("QQBrowser") > 0,
        BaiDu: this.u.indexOf("Baidu") > 0 || this.u.indexOf("BIDUBrowser") > 0,
        Maxthon: this.u.indexOf("Maxthon") > 0,
        LBBROWSER: this.u.indexOf("LBBROWSER") > 0,
        SouGou: this.u.indexOf("MetaSr") > 0 || this.u.indexOf("Sogou") > 0,
        IE: this.u.indexOf("MSIE") > 0 || this.u.indexOf("Trident") > 0,
        Firefox: this.u.indexOf("Firefox") > 0,
        Opera: this.u.indexOf("Opera") > 0 || this.u.indexOf("OPR") > 0,
        Safari: this.u.indexOf("Safari") > 0,
        Chrome: this.u.indexOf("Chrome") > 0 || this.u.indexOf("CriOS") > 0,
        Wechat: this.u.indexOf("MicroMessenger") > 0,
        Edge: this.u.indexOf("Edge") > 0 || this.u.indexOf("edge") > 0,
        //System or platform
        Windows: this.u.indexOf("Windows") > 0,
        Linux: this.u.indexOf("Linux") > 0,
        Mac: this.u.indexOf("Macintosh") > 0,
        Android: this.u.indexOf("Android") > 0 || this.u.indexOf("Adr") > 0,
        WP: this.u.indexOf("IEMobile") > 0,
        BlackBerry:
            this.u.indexOf("BlackBerry") > 0 ||
            this.u.indexOf("RIM") > 0 ||
            this.u.indexOf("BB") > 0,
        MeeGo: this.u.indexOf("MeeGo") > 0,
        Symbian: this.u.indexOf("Symbian") > 0,
        iOS: this.u.indexOf("like Mac OS X") > 0,
        iPhone: this.u.indexOf("iPh") > 0,
        iPad: this.u.indexOf("iPad") > 0,
        //equipment
        Mobile:
            this.u.indexOf("Mobi") > 0 || this.u.indexOf("iPh") > 0 || this.u.indexOf("480") > 0,
        Tablet:
            this.u.indexOf("Tablet") > 0 ||
            this.u.indexOf("iPad") > 0 ||
            this.u.indexOf("Nexus 7") > 0
    };
    readonly version: string
    readonly device: Device
    readonly engine: Engine
    readonly browser: BrowserApp
    readonly os: SystemOS
    readonly language: string
    constructor(op?:UserAgent) {
        if (this.match.Chrome) {
            this.match.Chrome = !(
                Number(this.match.Opera) +
                Number(this.match.BaiDu) +
                Number(this.match.Maxthon) +
                Number(this.match.SouGou) +
                Number(this.match.UC) +
                Number(this.match.QQ) +
                Number(this.match.LBBROWSER) +
                Number(this.match.Edge)
            );
        }
        if (this.match.Safari) {
            this.match.Safari = !(
                Number(this.match.Chrome) +
                Number(this.match.Opera) +
                Number(this.match.BaiDu) +
                Number(this.match.Maxthon) +
                Number(this.match.SouGou) +
                Number(this.match.UC) +
                Number(this.match.QQ) +
                Number(this.match.LBBROWSER) +
                Number(this.match.Edge)
            );
        }
        if (this.match.Mobile) {
            this.match.Mobile = !this.match.iPad;
        }

        const hash = {
            engine: ["WebKit", "Trident", "Gecko", "Presto"] as Engine[],
            browser: [
                "Chrome",
                "IE",
                "Firefox",
                "Opera",
                "Safari",
                "UC",
                "QQ",
                "BaiDu",
                "Maxthon",
                "SouGou",
                "LBBROWSER",
                "Wechat",
                "Edge"
            ] as BrowserApp[],
            os: [
                "Windows",
                "Linux",
                "Mac",
                "Android",
                "iOS",
                "iPhone",
                "iPad",
                "WP",
                "BlackBerry",
                "MeeGo",
                "Symbian"
            ] as SystemOS[] ,
            device: ["Mobile", "Tablet"] as Device[]
        };
        this.device = "PC";
        this.language = (function() {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            const g = (navigator?.browserLanguage || navigator.language).toLowerCase();
            return g === "c" ? "zh-cn" : g;
        })();
        this.browser = 'Chrome'
        this.engine ='WebKit'
        this.os = 'Windows'
        for (const s in hash) {
            const _s = s as keyof typeof hash
            for (let i = 0; i < hash[_s].length; i++) {
                const value = hash[_s][i];
                if (value != 'PC' && this.match[value]) {
                  switch (_s) {
                    case 'browser':
                      this.browser = hash[_s][i]
                      break;
                    case 'device':
                        this.device = hash[_s][i]
                        break;
                    case 'engine':
                      this.engine = hash[_s][i]
                      break;
                    case 'os':
                      this.os = hash[_s][i]
                      break;
                  }
                }
            }
        }
        //Version information
        const u = this.u
        const version = {
            Chrome: function() {
                return u.replace(/^.*Chrome\/([\d.]+).*$/, "$1");
            },
            Edge: function() {
                return u.replace(/^.*Edge\/([\d.]+).*$/, "$1");
            },
            IE: function() {
                let v = u.replace(/^.*MSIE ([\d.]+).*$/, "$1");
                if (v === u) {
                    v = u.replace(/^.*rv:([\d.]+).*$/, "$1");
                }
                return v !== u ? v : "";
            },
            Firefox: function() {
                return u.replace(/^.*Firefox\/([\d.]+).*$/, "$1");
            },
            Safari: function() {
                return u.replace(/^.*Version\/([\d.]+).*$/, "$1");
            },
            Maxthon: function() {
                return u.replace(/^.*Maxthon\/([\d.]+).*$/, "$1");
            },
            QQ: function() {
                return u.replace(/^.*QQBrowser\/([\d.]+).*$/, "$1");
            },
            BaiDu: function() {
                return u.replace(/^.*BIDUBrowser[\s\/]([\d.]+).*$/, "$1");
            },
            UC: function() {
                return u.replace(/^.*UBrowser\/([\d.]+).*$/, "$1");
            },
            Wechat: function() {
                return u.replace(/^.*MicroMessenger\/([\d.]+).*$/, "$1");
            },
            Opera: function() {
                return u.replace(/^.*Opera\/([\d.]+).*$/, "$1");
            },
            SouGou: function() {
                return u.replace(/^.*SouGou\/([\d.]+).*$/, "$1");
            },
            LBBROWSER: function() {
                return u.replace(/^.*LBBROWSER\/([\d.]+).*$/, "$1");
            },
        };
        this.version = "";
        if (this.browser && version[this.browser]) {
            this.version = version[this.browser]();
        }
    }
}


export function navigatorInfo(op?:UserAgent):Browser {
    return new Browser(op)
}