import { Plugin, ref } from "vue";

type MouseCb = (e: MouseEvent) => void;
type MouseKeyCbList = MouseCb[];

const winMouseCb = {
    down: {} as MouseKeyCbList,
    move: {} as MouseKeyCbList,
    up: {} as MouseKeyCbList
};

const mouseInfo = ref(new MouseEvent('div'))


export function addWinMouseOb(act: keyof typeof winMouseCb, cb: MouseCb) {
    if (Array.isArray(winMouseCb[act])) {
        winMouseCb[act].push(cb);
    } else {
        winMouseCb[act] = [];
        winMouseCb[act].push(cb);
    }
}

export function removeWinMouseOb (act: keyof typeof winMouseCb, cb: MouseCb) {
    winMouseCb[act].filter(cbFunc => cbFunc !== cb);
}


export function useGetMouseXY() {
    return mouseInfo.value
}

export const installWinMouse: Plugin = {
    install() {
        window.addEventListener("mousedown", e => {
            for (let index = 0; index < winMouseCb.down.length; index++) {
                winMouseCb.down[index](e);
            }
            mouseInfo.value = e
        });
        window.addEventListener("mousemove", e => {
            for (let index = 0; index < winMouseCb.move.length; index++) {
                winMouseCb.move[index](e);
            }
            mouseInfo.value = e
        });
        window.addEventListener("mouseup", e => {
            for (let index = 0; index < winMouseCb.up.length; index++) {
                winMouseCb.up[index](e);
            }
            mouseInfo.value = e
        });
    }
};
