import { useGlobal } from "@/global";
import { loginOut } from "@/utils/LoginOut";
import axios from "axios";
import { ElMessage } from "element-plus";
import { i18n } from "@/i18n/index";


const $t = i18n.global.t;
const _axios = axios.create();
_axios.defaults.headers = {
    "Content-Type": "application/x-www-form-urlencoded"
};
const _isLogin = useGlobal().isLogin

_axios.interceptors.request.use(
    function(config) {
        config.headers['Accept-Language'] = i18n.global.locale
        // 获取token过期时长
        const tokenExpiration = Number(useGlobal().userInfo.value.U_tokenExpirationTime) * 1000;
        if (tokenExpiration) {
            // 获取token记录时间
            const expirationTime = Number(localStorage.getItem("time")); // 当前时间
            const currentTime = new Date().getTime();
            const t = expirationTime + tokenExpiration;
            if (currentTime>t) {
                loginOut();
            }
        }
        
        // 在发送请求之前做些什么
        config.timeout = 60000;
        // console.log("Axios DEBUG >>>",config);
        return config;
    },
    function(error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

const map: Map<number, string> = new Map([
    [400, $t('server-fail-400')],
    [401, $t('server-fail-401')],
    [403, $t('server-fail-403')],
    [404, $t('server-fail-404')],
    [409, $t('server-fail-409')],
    [500, $t('server-fail-500')],
    [400090, $t('server-fail-400090')],
    [400091, $t('server-fail-400091')],
    [400101, $t('server-fail-400101')],
    [400102, $t('server-fail-400102')],
    [400103, $t('server-fail-400103')],
    [400104, $t('server-fail-400104')],
    [400105, $t('server-fail-400105')],
    [400200, $t('server-fail-400200')],
    [400201, $t('server-fail-400201')],
    [400202, $t('server-fail-400202')],
    [400203, $t('server-fail-400203')],
    [400204, $t('server-fail-400204')],
    [400205, $t('server-fail-400205')],
    [400206, $t('server-fail-400206')],
    [400207, $t('server-fail-400207')],
    [400208, $t('server-fail-400208')],
    [400209, $t('server-fail-400209')],
    [400210, $t('server-fail-400210')],
    [400212, $t('server-fail-400212')],
    [400213, $t('server-fail-400213')],
    [400214, $t('server-fail-400214')],
    [400219, $t('server-fail-400219')],
    [400220, $t('server-fail-400221')],
    [400221, $t('server-fail-400221')],
    [400222, $t('server-fail-400222')],
    [400300, $t('server-fail-400300')],
    [400301, $t('server-fail-400301')],
    [400302, $t('server-fail-400302')],
    [400310, $t('server-fail-400310')],
    [400311, $t('server-fail-400311')],
    [400312, $t('server-fail-400312')],
    [400313, $t('server-fail-400313')],
    [400314, $t('server-fail-400314')],
    [400320, $t('server-fail-400320')],
    [400321, $t('server-fail-400321')],
    [400331, $t('server-fail-400331')],
    [400332, $t('server-fail-400332')],
    [400333, $t('server-fail-400333')],
    [400334, $t('server-fail-400334')],
    [400335, $t('server-fail-400335')],
    [400401, $t('server-fail-400401')],
    [400402, $t('server-fail-400402')],
    [400403, $t('server-fail-400403')],
    [400404, $t('server-fail-400404')],
    [400900, $t('server-fail-4000900')],
    [400901, $t('server-fail-400901')],
    [400902, $t('server-fail-400902')],
    [400903, $t('server-fail-400903')],
    [400904, $t('server-fail-400904')],
    [400905, $t('server-fail-400905')],
    [400910, $t('server-fail-400910')],
    [400911, $t('server-fail-400911')],
    [1011006, ''] // token失效
]);

//添加响应拦截器
_axios.interceptors.response.use(
    response => {
        const data = response.data as {
            code: number;
            message: string;
            data: Record<string, unknown> | undefined;
            success: boolean;
        };
        if (data.code === 200 && data.success) {
            return response.data;
        } else if (data.code != 200 && !data.success) {
            // api服务错误
            if (!map.has(data.code)) {
                if(data.message) {
                    ElMessage({
                        type: 'error',
                        message: `${$t('query-error')} ${data.message}`,
                        customClass: "el-custom-msg"
                    });
                } else {
                    ElMessage({
                        type: 'error',
                        message: `${$t('query-error')}`,
                        customClass: "el-custom-msg"
                    });
                }
                
                console.error(`
                    接口：${response.config.url};
                    code：${data.code};
                    模式：${response.config.method};
                    参数：${response.config.params};
                    Data：${response.config.data};
                    服务器回报错误信息：${data.message}
                `, data)
            } else {
                if(data.code === 1011006 && _isLogin.value){
                    ElMessage({
                        type: 'warning',
                        message: data.message ?? map.get(data.code),
                        customClass: "el-custom-msg"
                    });
                    loginOut()
                }
                if (data.code === 400104) {
                    ElMessage({
                        type: 'warning',
                        message: data.message ?? map.get(data.code),
                        customClass: "el-custom-msg"
                    });
                } else {
                    ElMessage({
                        type: 'error',
                        message: data.message ?? map.get(data.code),
                        customClass: "el-custom-msg"
                    });
                }

            }

            return Promise.reject(response.data);
        } else if (!data.success) {
            // 未知错误
            console.warn(`未知错误; 参数问题 (code: ${data.code}): ${data.message}`, response.request);
            return response.data;
        }
    },
    error => {
        const errObj = {
            code: -1,
            msg: "",
            data: error
        };
        if (error && error.response && error.response.status) {
            // console.warn(error.response.status)

            switch (error.response.status) {
                case 403:
                    // notification.error({
                    //   message: 'Forbidden',
                    //   description: error.response.data.message
                    // })
                    // NotificAtion(error.response.mmsg)

                    break;
                case 402:
                    // notification.error({
                    //   message: 'Forbidden',
                    //   description: error.response.data.message
                    // })
                    // NotificAtion(error.response.msg)
                    break;
                case 401:
                    // notification.error({
                    //   message: 'Unauthorized',
                    //   description: '授权验证失败，请重新登录'
                    // })
                    // NotificAtion(error.response.msg)

                    // App.$router.replace({
                    //   path: '/login'
                    // })
                    break;
                case 500:
                    ElMessage({
                        type: 'error',
                        message: $t('server-error-500')
                    });

                    break;

                default:
                    break;
            }
        } else {
            console.error(error);
            errObj.msg = $t('unknown-error');
        }

        return Promise.reject(errObj);
    }
);

export default _axios;
