
import { defineComponent, ref } from "vue";
import layoutDefault from "./layout/default.vue";
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import enUs from "element-plus/lib/locale/lang/en";
import { useGlobal } from "./global";
import { i18n } from "./i18n";
export default defineComponent({
    components: {
        layoutDefault,
        ElConfigProvider
    },
    setup() {
        const locale = ref(zhCn);
        const swithcLang = (val: string) => {
            console.log("val", val);
            if (val == "zh-CN") {
                locale.value = zhCn;
            } else {
                locale.value = enUs;
            }
        };
        swithcLang(i18n.global.locale)
        return {
            global: useGlobal(),
            locale
        };
    }
});
