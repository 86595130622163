import { Plugin } from "vue";

export type CallBack = (e: Event) => void;

const callBacks = [] as CallBack[];

/**
 * 监听window.onresize事件
 */
function obWindowResize() {
    window.addEventListener("resize", e => {
        // 响应全部滚动
        callBacks.forEach(cb => {
            cb(e);
        });
    });
}

/**
 * 添加监听window滚动事件
 * @param type
 * @param cb
 */
function addResizeAction(cb: CallBack) {
    callBacks.push(cb);
}

const installWindowResize: Plugin = {
    install(app) {
        obWindowResize()
    }
};

function useWinResizeAction(cb: CallBack): void {
    addResizeAction(cb);
}

export { useWinResizeAction, installWindowResize };
