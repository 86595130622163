import { i18n } from "@/i18n";
import dayjs, { Dayjs } from "dayjs";

/**
 * 相异后入
 *
 * a 和 b相异 ，则返回b，相同返回a
 *
 * @param a 目标
 * @param b 替换
 */
export function replace<T>(a: any, b: any): T {
    if (a != b) {
        return b;
    } else {
        return a;
    }
}

/**
 * 字符串转Uint8Array
 * @param str 字符串
 * @returns { Uint8Array }
 */
export function string2Uint8Array(str: string):Uint8Array {
    const arr = [];
    for (let i = 0, j = str.length; i < j; ++i) {
        arr.push(str.charCodeAt(i));
    }
    const tmpUint8Array = new Uint8Array(arr);
    return tmpUint8Array;
}

  /**
   * 十进制转二进制(byte)
   *
   * byte 为长度为8的定长数组,值为 0 或 1
   *
   * @param val
   */
   export function num2binary(val:number):Array<0 | 1> {
    let binary = val.toString(2)
    binary = binary.padStart(8, '0')
    const list = binary.split('').map(b => Number(b) as 0 | 1 )
    return list
  }

  /**
   * 二进制(byte)转十进制
   * @param val
   */
  export function binay2num(val: Array<0 | 1>):number {
    return parseInt(val.toString().replace(/,/g, ''), 2)
  }

  /**
   * 获取多语言周天文本
   */
  export function getWeekDaysStr():string[] {
    const _lang = i18n.global.locale === "zh-CN" ? "zh" : "en";
    const firstDayOfWeek = dayjs.localeData().firstDayOfWeek();
    dayjs.locale(_lang);
    const _arry = [...dayjs.localeData().weekdays()];

    const _start = _arry.splice(firstDayOfWeek);
    return [..._start, ..._arry];
  }

  /**
   * 格式化周天文本顺序
   */
  export function formWeekDaysStr(weekDays:string[]):string[] {
    if(weekDays.length === 7) {
        const list = [...weekDays]
        list.unshift(...list.splice(6,7))
        return list
    } else {
        throw `周天文本数不对, 传入: ${weekDays.toString()}`
    }
  }
