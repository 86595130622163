import { addWinMouseOb } from "@/plugins/windowMouse";

/**
 * 监听全局 Button 标签的鼠标按下与抬起事件，
 * 如果按下与抬起都是同一个元素，则调用 blur 事件，
 * 取消按钮 Focus 状态
 */
export function blurBtnFocus():void {
    let _isBtn = false;
    let _target: HTMLElement;
    addWinMouseOb("down", e => {
        if (e.target) {
            const __target = e.target as HTMLElement;
            const ifIsBtn = (t:HTMLElement, num = 0):boolean => {
              if(t.tagName.toLocaleLowerCase() === 'button') {
                _target = t;
                return true
              } else {
                if(t.parentElement) {
                  return ifIsBtn(t.parentElement, num++)                
                } else if(num > 10){
                  return false
                } else {
                  return false
                }
              }
            }
            _isBtn = ifIsBtn(__target);
        }
    });

    addWinMouseOb('up',(e) => {
      if(_isBtn) {
        _target.blur()
      }
    })
}
