
import { GlobalConst, useGlobal } from "@/global";
import { ElMessage } from "element-plus";
import { useMqtt } from "./mqtt";

export function loginOut(): void {
    console.log('loginOut: login=' + useGlobal().isLogin.value, 'logout')
    if (useGlobal().isLogin.value === true) {
        useGlobal().uploadProcessRef.value?.close();
        const _timeout = useGlobal().timeoutLogin.value;
        if (_timeout !== null) { // 主动退出
            window.clearTimeout(_timeout);
        } else { // 登录时间到达阈值自动退出
            ElMessage({
                type: "warning",
                message: "登录到期，请重新登录"
            });
        }
        useGlobal().isLogin.value = false;
        useMqtt().closeMqtt();
        
        const lang = localStorage.getItem(GlobalConst.APPLANG_CACHE_NAME)
        localStorage.clear();
        lang && (
            localStorage.setItem(GlobalConst.APPLANG_CACHE_NAME, lang)
        )
    }
}
