
// icon
import "@/assets/iconfont/iconfont.css";
import "@/assets/iconfont/iconfont.js"; // 多色图标
import "@/assets/style/app.css";
import "@/assets/style/main.css";
// eslint-disable-next-line
import "dayjs/locale/zh-cn";
import { createApp } from "vue";
import {
    ElButton,
    ElTable,
    ElTimePicker,
    ElTimeSelect,
    ElTimeline,
    ElDatePicker,
    ElContainer,
    ElInput,
    ElCard,
    ElCheckbox,
    ElMenu,
    ElForm,
    ElLink,
    ElDropdown,
    ElSelect,
    ElRadio,
    ElMessage,
    ElDialog,
    ElPagination,
    ElCollapse,
    ElRow,
    ElSpace,
    ElTag,
    ElMessageBox,
    ElCascader,
    ElBadge,
    ElPopover,
    ElAvatar,
    ElDivider,
    ElLoading,
    ElTree,
    ElTabs,
    ElPopconfirm,
    ElCascaderPanel,
    ElUpload,
    ElSlider,
    ElDrawer,
    ElCol,
    ElSwitch,
    ElProgress,
    ElScrollbar,
    ElColorPicker,
    ElTooltip,
    ElAutocomplete,
    ElAffix,
    ElImage,
    ElDescriptions,
    ElBacktop,
    ElSteps,
    ElIcon,
    ElResult
} from "element-plus/lib";
import ElSvgIcons from "@element-plus/icons";
// import ElementPlus from "element-plus";

import App from "./App.vue";
import "./registerServiceWorker";

import router from "./router";
import { i18n } from "./i18n/index";
import { string2Uint8Array } from "@/utils/utils";
import { getLogin, getUserAuth, getUserInfo, refreshUserInfoAuth } from "@/utils/getLoginState";
import { installWindowResize } from "@/plugins/windowResize";
import { installWindowScorll } from "@/plugins/windowScroll";
import { addWinMouseOb, installWinMouse } from "@/plugins/windowMouse";
import { useWebfunny, watchUseinfo } from "./global";
import McSvg from "@/components/IconSvg.vue";
import dayjs from "dayjs";
import dayjsZhcn from "dayjs/locale/zh-cn";
import { getCorpCfg } from "./utils/getCorpInfo";
import { blurBtnFocus } from "./utils/blurButtonClickFocus";
import { getAppConfig } from "./utils/getAppConfig";

dayjs.locale(dayjsZhcn);

useWebfunny();

String.prototype.toUnit8Array = function(): Uint8Array {
    return string2Uint8Array(this.valueOf());
};

createWebApp()

async function createWebApp() {
    
    await getAppConfig();
    // 应用实例
    const app = createApp(App);
    blurBtnFocus();
    watchUseinfo();
    // 从localStorage获取登录状态
    const _login = getLogin();

    // APP启动
    const vueSetup = () => {
        app.use(router);
        app.mount("#app");
    }
    if (_login) {
        refreshUserInfoAuth().finally(() => {
            vueSetup()
        });
        getCorpCfg();
    } else {
        vueSetup()
    }

    app.use(ElLoading);
    app.use(installWindowResize);
    app.use(installWindowScorll);
    app.use(installWinMouse);

    app.component("McSvg", McSvg);

    // 按需注册 element-plus 组件
    app.use(ElResult);
    app.use(ElIcon);
    app.use(ElSteps);
    app.use(ElAffix);
    app.use(ElAutocomplete);
    app.use(ElSwitch);
    app.use(ElColorPicker);
    app.use(ElCascaderPanel);
    app.use(ElPopconfirm);
    app.use(ElTabs);
    app.use(ElTree);
    app.use(ElButton);
    app.use(ElTable);
    app.use(ElTimePicker);
    app.use(ElTimeSelect);
    app.use(ElTimeline);
    app.use(ElDatePicker);
    app.use(ElContainer);
    app.use(ElInput);
    app.use(ElCard);
    app.use(ElCheckbox);
    app.use(ElMenu);
    app.use(ElForm);
    app.use(ElLink);
    app.use(ElDropdown);
    app.use(ElRadio);
    app.use(ElSelect);
    app.use(ElMessage);
    app.use(ElDialog);
    app.use(ElPagination);
    app.use(ElCollapse);
    app.use(ElRow);
    app.use(ElSpace);
    app.use(ElTag);
    app.use(ElMessageBox);
    app.use(ElCascader);
    app.use(ElBadge);
    app.use(ElPopover);
    app.use(ElAvatar);
    app.use(ElDivider);
    app.use(ElUpload);
    app.use(ElDrawer);
    app.use(ElCol);
    app.use(ElSlider);
    app.use(ElProgress);
    app.use(ElScrollbar);
    app.use(ElTooltip);
    app.use(ElImage);
    app.use(ElDescriptions);
    app.use(ElBacktop);

    // 注册i18n国际化组件
    app.use(i18n);
}
