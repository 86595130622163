import { useGlobal } from "@/global";
import { connect, MqttClient, OnErrorCallback, OnMessageCallback, IPublishPacket } from "mqtt";
import { uuid } from "./uuid";

type MQTTErroFunc = OnErrorCallback;

type TaskDataType = {
    corpId: string;
    status: number;
    downloadedSize: string;
    [k: string]: unknown;
};

let client: MqttClient | undefined;
let hiddenAtTime = 0;
const errorFuncs: Array<MQTTErroFunc> = [];
const msgCallback: Array<OnMessageCallback> = [];
let _windowVisibility = true;
const _watchVisibilityFunc = () => {
    console.log("visibilitychange", document.visibilityState);
    _windowVisibility = document.visibilityState !== "hidden";
    if (client && _windowVisibility && useGlobal().isLogin.value && !client.connected) {
        console.log("重新连接 MQTT");
        client.reconnect();
    }


    // 解决MQTT长时间未响应后，浏览器会主动断开websocket连接
    if (!_windowVisibility) {
        hiddenAtTime = new Date().getTime();
    } else if (useGlobal().isLogin.value) {
        // 用户离开页面超过一分钟后，强制刷新页面
        if (new Date().getTime() - hiddenAtTime > 60000) {
            console.log('触发重连接MQTT');
            window.location.reload();
            // client && client.reconnect();
        }
    }

    // if (client) {
    //     if (!_windowVisibility) {
    //         console.log('Close it', 'invisible')
    //         closeMqtt()
    //     }
    // }
    // else if (useGlobal().isLogin.value) {
    //     if (_windowVisibility) {
    //         console.log('Open it', 'visible')
    //         useMqtt()
    //     }
    // }
};

export const addErrorMqttFunc = (cb: MQTTErroFunc) => {
    errorFuncs.push(cb);
};

/**
 * 添加 MQTT msg 事件回调
 * @param cb
 */
export const addMsgCallback = (cb: OnMessageCallback) => {
    msgCallback.push(cb);
};
/**
 * 删除 MQTT msg 事件回调
 * @param cb
 */
export const clearMsgCallback = (cb: OnMessageCallback) => {
    const _index = msgCallback.indexOf(cb);
    console.log("clearMsgCallback:" + _index, "cls");
    if (_index > -1) {
        msgCallback.splice(_index, 1);
    }
};

const createMqtt = (url: string, port: number) => {
    const _info = useGlobal().userInfo;


    client = connect(`ws://${url}`, {
        port: port,
        path: "/mqtt",
        protocol: port == 443 ? "mqtts" : "mqtt",
        username: `corp-user|${_info.value.U_Id}`,
        password: `token|${_info.value.U_token}`,
        clientId: `token|${_info.value.U_token}|${uuid()}`,
        clean: true,
        reconnectPeriod: 5000,
        keepalive: 30 // TEST
    });

    client.on("reconnect", () => {
        // console.log("MQTT reconnect 重新连接");
        if (!_windowVisibility) {
            console.log("MQTT 主动断开");
            closeMqtt();
        }
    });
    client.on("disconnect", res => {
        console.warn("MQTT 断开连接", res);
    });
    client.on("error", err => {
        console.error("MQTT ERROR", err);
        errorFuncs.forEach(cb => {
            cb(err);
        });
    });

    client.on("message", (topic: string, data: Buffer, packet: IPublishPacket) => {
        msgCallback.forEach(item => {
            console.log('receiveMsg,topic:' + topic)
            item(topic, data, packet);
        });
    });

    return client;
};

/**
 * 关闭MQTT连接
 * @returns {Promise<any | Error>}
 */
const closeMqtt = (): Promise<any | Error> => {
    return new Promise((res, rej) => {
        if (client) {
            console.log("client end", "mqt");
            client.end(true, {}, () => {
                res({});
                addErrorMqttFunc(err => {
                    rej(err);
                });
            });
            client = undefined;
            msgCallback.splice(0);
        } else {
            rej(new Error("client is undefined"));
        }
    });
};
/**
 * 获取全局 mqtt.Client 对象, 没有则创建一个
 * @returns {mqtt.Client, closeMqtt}
 */
export const useMqtt = (op?: { url: string; port: number }) => {
    const _op = op ?? {
        url: location.hostname,
        port: location.protocol === "https:" ? 443 : 80
    };
    if (!client) {
        console.log("create mqt", "mqt");
        if (process.env.NODE_ENV === "production") {
            createMqtt(_op.url, _op.port);
        } else {
            _op.url = process.env.VUE_APP_MQTTSERVERURL ?? "192.168.1.23";
            _op.port = _op.url.indexOf("mooncell") > -1 ? 443 : 80;
            createMqtt(_op.url, _op.port);
        }
        document.addEventListener("visibilitychange", _watchVisibilityFunc);
    } else {
        console.log(client.connected, "mqtt connected state");
        if (!client.connected) {
            client.reconnect();
        }
    }

    return {
        /**
         * 全局 mqtt.Client 对象
         */
        client: client!,
        closeMqtt
    };
};
