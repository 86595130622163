import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "multicolor-icon text-xl",
  "aria-hidden": "true"
}
const _hoisted_2 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.mode === 'svg')
    ? (_openBlock(), _createElementBlock("svg", _hoisted_1, [
        _createElementVNode("use", {
          "xlink:href": `#icon-mc-${_ctx.type}`
        }, null, 8, _hoisted_2)
      ]))
    : (_openBlock(), _createElementBlock("i", {
        key: 1,
        class: _normalizeClass(["iconfont", `icon-mc-${_ctx.type}`])
      }, null, 2))
}