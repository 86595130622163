/**
 * McApp 注册中心
 */

import { InstallAPP } from "@/Application";

/**
 * 注册的McApp列表
 */
export const MC_APP_LIST:InstallAPP[] = [
  {
    name: 'app_standard',
    install: () => import(/* webpackChunkName: "app_standard" */ "@/package/App/standard/app")
  },
  {
    name: 'app_led',
    install: () => import(/* webpackChunkName: "app_led" */ "@/package/App/led/app")
  }
]