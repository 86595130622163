/* eslint-disable no-console */

import _Select from 'element-plus/lib/components/select-v2';
import { register } from 'register-service-worker';
import { useGlobal } from './global';

if (process.env.NODE_ENV === 'production') {
  const upVersion = (flag:boolean) => {
    const _app = useGlobal().App.value
    if(_app) {
      // console.log('set newVersion', flag);
      useGlobal().App.value!.newVersion = flag
    }
  }
  register(`${process.env.BASE_URL}service-worker.js`, {
    registrationOptions:{ scope: './'},
    ready(reg) {
      // console.log(
      //   'App is being served from cache by a service worker.\n'
      //   + 'For more details, visit https://goo.gl/AFskqB',
      // );

      document.addEventListener('visibilitychange', (e) => {
        if(document.visibilityState === 'visible') {
          reg.update()
        }
      })
    },
    registered(reg) {
    },
    cached() {
      // console.log('Content has been cached for offline use.');
    },
    updatefound(reg) {
      console.log('New version downloading.');
      
      upVersion(false)
    },
    updated(reg) {
      // console.log("更新成功");
      upVersion(true)
    },
    offline() {
      // console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      // console.error('Error during service worker registration:', error);
    },
  });
}
