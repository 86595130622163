import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_layout_default = _resolveComponent("layout-default")!
  const _component_el_config_provider = _resolveComponent("el-config-provider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_config_provider, { locale: _ctx.locale }, {
      default: _withCtx(() => [
        _createVNode(_component_layout_default, { class: "min-w-screen" })
      ]),
      _: 1
    }, 8, ["locale"])
  ]))
}