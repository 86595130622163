import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b798fa1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-between w-full items-center bg-indigo-50" }
const _hoisted_2 = {
  key: 0,
  class: "mx-4"
}
const _hoisted_3 = {
  key: 1,
  class: "mx-4"
}
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { class: "mx-2" }
const _hoisted_6 = { class: "mx-2" }
const _hoisted_7 = {
  key: 0,
  class: "flex-row overflow-y-auto overflow-x-hidden max-h-300px w-full px-20px pb-20px"
}
const _hoisted_8 = { class: "text-xs flex justify-between my-2" }
const _hoisted_9 = { class: "text-blue-500" }
const _hoisted_10 = {
  class: "multicolor-icon text-xl",
  "aria-hidden": "true"
}
const _hoisted_11 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_progress = _resolveComponent("el-progress")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_ctx.isShowUploadDialog)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([{
            'bottom-0': _ctx.shrink,
            'bottom-8': !_ctx.shrink
        }, "shadow-2xl flex flex-col justify-center items-center bg-white right-24 fixed border border-blue-300 w-530px"]),
        style: {"z-index":"4000"}
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            (_ctx.isSucceed)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("upload-success")), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("upload-wait")), 1)),
            (_ctx.progressInfo)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("upload-file-success")) + " (" + _toDisplayString(_ctx.progressInfo.succeedNum) + ")", 1),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("upload-file-fail")) + " (" + _toDisplayString(_ctx.progressInfo.errorNum) + ")", 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_el_button, {
              icon: _ctx.shrink ? 'el-icon-full-screen' : 'el-icon-minus',
              size: "small",
              text: "",
              class: "cursor-pointer text-xl shrink",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.shrink = !_ctx.shrink))
            }, null, 8, ["icon"]),
            (_ctx.isSucceed)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  icon: "el-icon-close",
                  size: "small",
                  text: "",
                  class: "cursor-pointer text-xl close text-gray-600",
                  onClick: _ctx.close
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ]),
        (_ctx.progressInfo)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("upload-warning")), 1)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.progressInfo.updatedTask, (item, i) => {
                return (_openBlock(), _createBlock(_component_el_row, {
                  key: i,
                  class: "border border-l-0 border-r-0 p-2 text-sm flex items-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { span: 2 }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createElementBlock("svg", _hoisted_10, [
                          _createElementVNode("use", {
                            "xlink:href": _ctx.iconType(item.type)
                          }, null, 8, _hoisted_11)
                        ]))
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_el_col, {
                      span: 6,
                      class: "overflow-ellipsis overflow-hidden whitespace-pre",
                      title: item.name
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["title"]),
                    _createVNode(_component_el_col, {
                      span: 6,
                      class: "pl-2"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.size), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_el_col, {
                      span: 10,
                      class: "pl-2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_progress, {
                          class: "h-6",
                          "stroke-width": 12,
                          percentage: item.progress,
                          status: item.singleState
                        }, null, 8, ["percentage", "status"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ], 512)), [
              [_vShow, !_ctx.shrink]
            ])
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}