
import { useGlobal, ProgressInfoState } from "@/global";
import { defineComponent, ref, watch, computed } from "vue";

export default defineComponent({
    name: "UploadProcess",
    setup() {
        const _global = useGlobal();
        /**
         * @description: 进度窗口是否显示
         */
        const isShowUploadDialog = ref(false);
        isShowUploadDialog.value = _global.isShowProgress.value;
        watch(_global.isShowProgress, v => {
            isShowUploadDialog.value = v;
        });
        const progressInfo = ref<ProgressInfoState>();
        progressInfo.value = _global.progressInfo;
        watch(_global.progressInfo, v => {
            progressInfo.value = v;
        });
        const isSucceed = computed(
            () =>
                _global.progressInfo.updatedTask.length ===
                _global.progressInfo.succeedNum + _global.progressInfo.errorNum
        );

        const close = () => {
            _global.isShowProgress.value = false;
            if (progressInfo.value) {
                _global.progressInfo.errorNum = 0;
                _global.progressInfo.succeedNum = 0;
                _global.progressInfo.updatedTask.forEach(task => {
                    task.alioss.cancel && task.alioss.cancel();
                });
                _global.progressInfo.updatedTask = [];
            }
        };
        /**
         * @description:
         * @param 0：未知类型
         * @param 1：图片
         * @param 2：视频
         * @param 3：音频
         * @param 4：文档
         * @return {className} svg图标类名
         */
        const iconType = (type: number) => {
            switch (type) {
                case 0:
                    return "#icon-mc-tupianposun";
                case 1:
                    return "#icon-mc-image";
                case 2:
                    return "#icon-mc-video";
                case 3:
                    return "#icon-mc-yinle";
                case 4:
                    return "#icon-mc-pdf";
                default:
                    return "#icon-mc-wenjian";
            }
        };
        return {
            isShowUploadDialog,
            progressInfo,
            isSucceed,
            shrink: ref(false),
            iconType,
            close
        };
    }
});
