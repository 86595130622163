import { useGlobal, GlobalConst } from "@/global";
import { i18n } from "@/i18n";
import {
    createRouter,
    RouteRecordRaw,
    createWebHistory,
    _RouteRecordBase,
    RouteLocationNormalized,
    RouteComponent,
    RouteRecordRedirectOption,
    RouteLocation,
    createWebHashHistory,
} from "vue-router";
import common from "@/layout/common.vue";
import { ProgramView } from "@/package/Program/type";

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
// component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
declare type Lazy<T> = () => Promise<T>;

type _RouteRecordProps =
    | boolean
    | Record<string, any>
    | ((to: RouteLocationNormalized) => Record<string, any>);

export type MenuRoute = MCRouteRecordSingleView | MCRouteRecordRedirect | MCRouteRecordSingleView;
interface RoutePageBase {
    /**
     * 路由标题
     *
     * _路由标题一般用于菜单栏组件_
     */
    $title?: string;
    /**
     * 是否显示
     *
     * _用户是否有该路由的显示权限_
     */
    isShow?: () => boolean;
    /**
     * 路由图标
     *
     * _路由图标一般用于菜单栏组件_
     */
    icon?: string;
}
interface MCRouteRecordSingleView extends _RouteRecordBase, RoutePageBase {
    component: RouteComponent | Lazy<RouteComponent>;
    components?: never;
    children?: MenuRoute[];
}
interface MCRouteRecordRedirect extends _RouteRecordBase, RoutePageBase {
    redirect: RouteRecordRedirectOption;
    component?: never;
    components?: never;
    children?: MenuRoute[];
}

interface MCRouteRecordSingleView extends _RouteRecordBase, RoutePageBase {
    component: RouteComponent | Lazy<RouteComponent>;
    components?: never;
    props?: _RouteRecordProps;
}

/**
 * i18n翻译函数
 */
const t = i18n.global.t;

/**
 * 向路由最后添加 / 符号
 * @param path 路由
 * @returns 返回路由
 */
export function addPathLastSlash(path: string) {
    return /\/$/.test(path) ? path : path + "/";
}

/**
 * 路由重定向到某一个子路由
 * @param target 重定向目标
 * @returns 路由重定向方法 ((to: RouteLocation) => RouteLocationRaw)
 */
export function redirectAct(target: string) {
    return (to: RouteLocation) => {
        return `${addPathLastSlash(to.fullPath)}${target}`;
    };
}

/**
 * 云平台用户管理页面路由
 *
 * @returns 路由表
 */
export function cloudSystemConfigRoutes(): MenuRoute {
    const powerInfo = useGlobal().userPowerInfo;
    // console.log(powerInfo,"权限1");
    const _routs: MenuRoute[] = [
        {
            isShow: () => {
                return (
                    powerInfo.value.has("GET /corp/user/one") ||
                    powerInfo.value.has("GET /corp/player/auth/info") ||
                    powerInfo.value.has("GET /corp/one")
                );
            },
            path: "baseinfo",
            name: "baseinfo",
            $title: t("menu-baseinfo"),
            component: common,
            children: [
                {
                    path: "",
                    redirect: redirectAct("personal"),
                },
                {
                    isShow: () => powerInfo.value.has("GET /corp/user/one"),
                    path: "personal",
                    icon: "icon-mc-personalSettings iconfont text-indigo-500",
                    name: "baseinfo-personal",
                    $title: t("baseinfo-title"),
                    component: () =>
                        import(/* webpackChunkName: "baseinfo" */ "../views/UserInfo.vue"),
                },
                {
                    isShow: () => powerInfo.value.has("GET /corp/player/auth/info"),
                    path: "playerAuth",
                    icon: "icon-mc-playerManage iconfont text-purple-400",
                    name: "baseinfo-playerAuth",
                    $title: t("player-auth"),
                    beforeEnter(_, __, next) {
                        if (powerInfo.value.has("GET /corp/player/auth/info")) {
                            next();
                        } else {
                            next({ name: "baseinfo-personal" });
                        }
                    },
                    component: () =>
                        import(/* webpackChunkName: "baseinfo" */ "../views/PlayerAuth.vue"),
                },
                {
                    isShow: () => powerInfo.value.has("GET /corp/one"),
                    path: "enterpriseInformation",
                    icon: "icon-mc-info iconfont text-green-400",
                    name: "baseinfo-enterpriseInformation",
                    $title: t("enterprise-info"),
                    beforeEnter(_, __, next) {
                        if (powerInfo.value.has("GET /corp/one")) {
                            next();
                        } else {
                            next({ name: "baseinfo-personal" });
                        }
                    },
                    component: () =>
                        import(
                            /* webpackChunkName: "baseinfo" */ "../views/EnterpriseInformation.vue"
                        ),
                },
            ],
        },
        {
            isShow: () =>
                powerInfo.value.has("POST /corp/user/list") ||
                powerInfo.value.has("POST /corp/user/role/list") ||
                (powerInfo.value.has("GET /corp/team/tree") &&
                    powerInfo.value.has("POST /corp/team/create")),
            path: "group",
            name: t("zu-zhi-jia-gou"),
            $title: t("zu-zhi-jia-gou"),
            redirect: "group/userManagement",
            component: common,
            beforeEnter(_, __, next) {
                if (
                    powerInfo.value.has("POST /corp/user/list") ||
                    powerInfo.value.has("POST /corp/user/role/list") ||
                    (powerInfo.value.has("GET /corp/team/tree") &&
                        powerInfo.value.has("POST /corp/team/create"))
                ) {
                    next();
                } else {
                    next({ name: "baseinfo-personal" });
                }
            },
            children: [
                {
                    isShow: () => powerInfo.value.has("POST /corp/user/list"),
                    path: "UserManagement",
                    icon: "icon-mc-userManagement iconfont text-blue-400",
                    name: "group-UserManagement",
                    $title: t("User Management"),
                    beforeEnter(_, __, next) {
                        if (powerInfo.value.has("POST /corp/user/list")) {
                            next();
                        } else {
                            next({ name: "baseinfo-personal" });
                        }
                    },
                    component: () =>
                        import(/* webpackChunkName: "group" */ "../views/UserManagement.vue"),
                },
                {
                    isShow: () => powerInfo.value.has("POST /corp/user/role/list"),
                    path: "RoleManagement",
                    icon: "icon-mc-roleManagement iconfont text-Udefined-orange",
                    name: "group-RoleManagement",
                    $title: t("role-management"),
                    beforeEnter(_, __, next) {
                        if (powerInfo.value.has("POST /corp/user/role/list")) {
                            next();
                        } else {
                            next({ name: "baseinfo-personal" });
                        }
                    },
                    component: () =>
                        import(/* webpackChunkName: "group" */ "../views/RoleManagement.vue"),
                },
                {
                    isShow: () =>
                        powerInfo.value.has("GET /corp/team/tree") &&
                        powerInfo.value.has("POST /corp/team/create"),
                    path: "GroupManagement",
                    icon: "icon-mc-teamManagement iconfont text-yellow-400",
                    name: "group-GroupManagement",
                    $title: t("gong-zuo-zu-guan-li"),
                    beforeEnter(_, __, next) {
                        if (
                            powerInfo.value.has("GET /corp/team/tree") &&
                            powerInfo.value.has("POST /corp/team/create")
                        ) {
                            next();
                        } else {
                            next({ name: "baseinfo-personal" });
                        }
                    },
                    component: () =>
                        import(/* webpackChunkName: "group" */ "../views/GroupManagement.vue"),
                },
            ],
        },
        {
            isShow: () => powerInfo.value.has("GET /corp/user/config/get"),
            path: "system",
            name: t("systemConfig"),
            $title: t("systemConfig"),
            redirect: "system/applicationConfig",
            component: common,
            beforeEnter(_, __, next) {
                if (powerInfo.value.has("GET /corp/user/config/get")) {
                    next();
                } else {
                    next({ name: "baseinfo-personal" });
                }
            },
            children: [
                {
                    isShow: () => powerInfo.value.has("GET /corp/user/config/get"),
                    path: "applicationConfig",
                    icon: "icon-mc-appConfig iconfont text-green-400",
                    name: "system-applicationConfig",
                    $title: t("Application configuration"),
                    component: () =>
                        import(/* webpackChunkName: "group" */ "../views/ApplyConfig.vue"),
                },
            ],
        },
        {
            isShow: () => powerInfo.value.has("POST /corp/user/log/list"),
            path: "log",
            name: t("yong-hu-ri-zhi"),
            $title: t("yong-hu-ri-zhi"),
            redirect: "/log/userLoginLog",
            component: common,
            beforeEnter(_, __, next) {
                if (powerInfo.value.has("POST /corp/user/log/list")) {
                    next();
                } else {
                    next({ name: "baseinfo-personal" });
                }
            },
            children: [
                {
                    isShow: () => powerInfo.value.has("POST /corp/user/log/list"),
                    path: "userLoginLog",
                    icon: "icon-mc-userJournal iconfont text-red-400",
                    name: "log-userLoginLog",
                    $title: t("yong-hu-deng-lu-ri-zhi"),
                    component: () =>
                        import(/* webpackChunkName: "log" */ "../views/UserLoginLog.vue"),
                },
            ],
        },
    ];
    _routs.push({
        path: "",
        redirect: (to) => {
            // console.log(to.fullPath);
            let _goPath = "";
            for (let index = 0; index < _routs.length; index++) {
                const el = _routs[index];
                if (el.isShow?.()) {
                    _goPath = el.path;
                    break;
                }
            }
            return `${addPathLastSlash(to.fullPath)}${_goPath}`;
        },
    });
    return {
        path: "system",
        name: "system",
        $title: t("systemConfig"),
        component: () => import(/* webpackChunkName: "cloudSystem" */ "../layout/systemConfig.vue"),
        children: _routs,
    };
}

/**
 * 公开路由
 *
 * @returns 路由列表
 */
export function publicRoutes(): Array<MenuRoute> {
    return [
        {
            path: "/program/preview",
            name: "program-preview",
            $title: "节目预览",
            component: () =>
                import(
                    /* webpackChunkName: "Program-Preview" */ "../package/ProgramPreview/Preview.vue"
                ),
        },
        {
            path: "/404",
            name: "404",
            $title: "404",
            component: () => import(/* webpackChunkName: "404page" */ "../views/404.vue"),
        },
        {
            path: "/program",
            name: "program-edit",
            $title: t("jie-mu-bian-ji"),
            component: () =>
                import(/* webpackChunkName: "Program" */ "../package/Program/Program.vue"),
        },
        {
            path: "/login",
            name: "login",
            $title: t("log in"),
            component: () => import(/* webpackChunkName: "login" */ "../views/LoginPage.vue"),
        },
        {
            path: "/signup",
            name: "signup",
            $title: t("signup-title"),
            component: () => import(/* webpackChunkName: "signup" */ "../views/SignUp/Index.vue"),
            children: [
                {
                    path: "",
                    name: "signup-start",
                    $title: t("signup-title"),
                    component: () =>
                        import(/* webpackChunkName: "signup" */ "../views/SignUp/SignUp.vue"),
                },
                {
                    path: "corp",
                    name: "signup-corp",
                    $title: t("signup-title"),
                    component: () =>
                        import(/* webpackChunkName: "signup" */ "../views/SignUp/CorpInfo.vue"),
                },
                {
                    path: "success",
                    name: "signup-success",
                    $title: t("signup-title"),
                    component: () =>
                        import(/* webpackChunkName: "signup" */ "../views/SignUp/Success.vue"),
                },
            ],
        },
        {
            path: "/passwordreset",
            name: "passwordreset",
            $title: t("password-reset"),
            component: () =>
                import(/* webpackChunkName: "passwordreset" */ "../views/PasswordReset.vue"),
        },
        {
            path: "/termsService",
            name: "termsService",
            $title: t("fu-wu-tiao-kuan"),
            component: () =>
                import(/* webpackChunkName: "termsService" */ "../views/termsService.vue"),
        },
    ];
}

const createAppRouter = () => {
    // 用平台应用目录
    return {
        path: GlobalConst.APP_PATH_ROOT,
        component: () => import("../layout/appliance.vue"),
        children: [
            {
                path: "",
                redirect: redirectAct("system"),
            },
            cloudSystemConfigRoutes(),
            // cloudStandardRoutes()
        ],
    };
};

export const routes: Array<MenuRoute> = [
    {
        path: "",
        redirect: "/login",
    },
    createAppRouter(),
    ...publicRoutes(),
];

/**
 * 路由 name 所对应的标题
 * route.name 对应的 route.$title
 *
 *
 */
export const routesTitleMap = new Map<string, string>();
/**
 * 写入路由标题
 * @param init 是否开启初始化
 */
const setRoutesTitleMap = (init = true) => {
    const _loop = (_c: Array<MenuRoute>) => {
        _c.forEach((route) => {
            if (init) {
                if (route.name && route.$title) {
                    if (routesTitleMap.has(route.name.toString())) {
                        console.warn(
                            `路由(${route.path})名称重复，val:`,
                            route.name?.toString(),
                            "title:",
                            route.$title
                        );
                    } else {
                        routesTitleMap.set(route.name.toString(), route.$title);
                    }
                } else {
                }
            } else {
                if (route.name && route.$title) {
                    routesTitleMap.set(route.name.toString(), route.$title);
                }
            }
            if (route.children && route.children.length) {
                _loop(route.children);
            }
        });
    };

    _loop(routes);
};
setRoutesTitleMap();

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const _title = to.name ? routesTitleMap.get(to.name.toString()) ?? "" : "";
    const _lang = i18n.global.locale.replace("-", "") as "enUS" | "zhCN";
    window.document.title = `${useGlobal().App.value?.systemName[_lang]}${
        _title.length ? ` | ${_title}` : ""
    }`;

    if (!useGlobal().App.value) {
        if (to.name !== "404") {
            next({
                name: "404",
            });
        } else {
            next();
        }
        return;
    }

    const _global = useGlobal();
    const _loginRex = new RegExp(/\/login/);
    const _signupRex = new RegExp(/\/signup/);
    const _passwordResSetRex = new RegExp(/\/passwordreset/);
    const _termsServiceRex = new RegExp(/\/termsService/);
    // 是否为公开目录
    if (
        (_signupRex.test(to.fullPath) && useGlobal().App.value?.showRegister) ||
        _passwordResSetRex.test(to.fullPath) ||
        _termsServiceRex.test(to.fullPath)
    ) {
        next();
    } else if (!_loginRex.test(to.fullPath) && !_global.isLogin.value) {
        // 检查是否登录
        next({ path: "/login" });
    } else {
        const _islogin = _global.isLogin.value;
        const _isLogPage = _loginRex.test(to.fullPath);

        // if(!router.hasRoute(to.fullPath)) {
        //     next({path: '/404'})
        //     return;
        // }

        if (_isLogPage && _islogin) {
            next({
                path: `${GlobalConst.APP_PATH_ROOT}${GlobalConst.CLOUD_PATH_SYSTEM_CONFIG}`
            });
            return;
        }
        // else if (_islogin && to.redirectedFrom) {
        //     const _path = to.redirectedFrom.path;
        //     console.log(_path, "_path");
        //     const isInclude = router.getRoutes().some((el) => el.path === _path);
        //     console.log(router.getRoutes(), isInclude);

        //     if (_path === "/") {
        //         next({
        //             path: `${GlobalConst.APP_PATH_ROOT}${GlobalConst.CLOUD_PATH_SYSTEM_CONFIG}`,
        //         });
        //         return;
        //     } else {
        //         next({ path: _path });
        //         return;
        //     }
        // }

        // 登录状态下进入404页面
        if (to.name == "404" && _islogin) {
            next({ path: `${GlobalConst.APP_PATH_ROOT}${GlobalConst.CLOUD_PATH_SYSTEM_CONFIG}` });
            return;
        }
        const _path = to.path;
        const isInclude = router.getRoutes().some((el) => _path === el.path || _path === '/');
        if (isInclude) {
            next();
        } else {
            next({
                name: '404'
            });
        }
    }
});

export default router;
