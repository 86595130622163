import { ApiRutenType } from "@/@types/Utils";
import useApi from "@/api/apiList";
import { initMcCloud } from "@/Application";
import { useGlobal } from "@/global";
import router from "@/router";
import { number } from "@intlify/core-base";
import { loginOut } from "./LoginOut";

type UserInfo = ApiRutenType<typeof useApi, "postCorpUserAuthLogin">;

/**
 * 从本地获取登录状态
 * @returns boolean
 */
export function getLogin(): boolean {
    let islogin = localStorage.getItem("islogin");
    const _oldUserInfoJson = localStorage.getItem("userInfo");
    const _loginTim = localStorage.getItem("time");
    if (_oldUserInfoJson && _loginTim) {
        const _old = JSON.parse(_oldUserInfoJson) as UserInfo;
        const _isMaxLoginTime = Number(_loginTim) + Number(_old.accessTokenExpireInSeconds) * 1000;
        const _nowTime = new Date().getTime();

        if (_nowTime > _isMaxLoginTime) {
            islogin = "false";
            localStorage.clear();
        }
    }
    return (useGlobal().isLogin.value = islogin === "true" ? true : false);
}

/**
 * 从本地获取用户信息
 */
export function getUserInfo(): void {
    const userInfo = localStorage.getItem("userInfo");

    const _userInfo = useGlobal().userInfo.value;
    if (userInfo) {
        const infoJson = JSON.parse(userInfo) as UserInfo;
        timeoutLogin(Number(infoJson.accessTokenExpireInSeconds));
        _userInfo.U_token = infoJson.accessToken;
        _userInfo.U_tokenExpirationTime = infoJson.accessTokenExpireInSeconds;
        _userInfo.U_avatar = infoJson.avatar;
        _userInfo.corpId = infoJson.corpId;
        _userInfo.U_name = infoJson.name;
        _userInfo.U_Id = infoJson.userId;
    }
}

/**
 * 从本地获取用户权限
 */
export function getUserAuth(): void {
    const _global = useGlobal().userPowerInfo.value;
    if (!_global.size) {
        const userPower = localStorage.getItem("userPower");
        if (userPower) {
            useGlobal().userPowerInfo.value = new Map(JSON.parse(userPower));
        } else {
            loginOut();
            router.replace("/login");
        }
    }
}
const timeoutLogin = (accessTokenExpireInSeconds: number) => {
    useGlobal().timeoutLogin.value = window.setTimeout(
        () => {
            useGlobal().timeoutLogin.value = null;
            loginOut();
        },
        isNaN(accessTokenExpireInSeconds) ? 36000 * 1000 : accessTokenExpireInSeconds * 1000
    );
};
/**
 * 拉取用户授权信息
 * @returns 
 */
export function refreshUserInfoAuth():Promise<boolean> {
    return useApi()
        .getCorpUserAuthInfo({
            req_body_other: {}
        })
        .then(async (res) => {
            if (res.code === 200) {
                const { perms, apps } = res.data
                try {
                    await initMcCloud(perms, apps)
                    return true;
                } catch (error) {
                    return false;    
                }
            } else {
                return false;
            }
        });
}
