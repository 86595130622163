/**
 * 此文件是项目全局运行时的全局变量与全局方法
 */

import { ref } from "@vue/reactivity";
import { watch, WatchStopHandle } from "@vue/runtime-core";
import { isRef, reactive, Ref } from "vue";
import { useRouter } from "vue-router";
import { ApiRutenType } from "./@types/Utils";
import useApi from "./api/apiList";
import { navigatorInfo } from "./utils/navigatorInfo";
import dayjs from "dayjs";
import { useMqtt } from "./utils/mqtt";
import { i18n } from "@/i18n/index";
import UploadProcess from "./components/UploadProcess.vue";
import config from "../package.json";
import AliOSS from "ali-oss";
import { ProgramScreen, ProgramView } from "./package/Program/type";
import { McAppManage } from "@/Application";

const $t = i18n.global.t;

type CorpCfg = ApiRutenType<typeof useApi, "getCorpPropOne">;

/**
 * 阿里OSS 对象
 *
 * fix 原生对象未包含 cancel 方法
 */
export type ALIOSS = AliOSS & {
    /**
     * 关闭任务
     */
    cancel: () => void;
};

export interface UserInfoState {
    /**
     * 访问token
     */
    U_token: string;
    /**
     * accessToken有效时长，单位（秒）
     */
    U_tokenExpirationTime: string | undefined;
    /**
     * 用户头像，URL链接
     */
    U_avatar: string;
    /**
     * 所属企业ID
     */
    corpId: string;
    /**
     * 用户姓名
     */
    U_name: string;
    /**
     * 用户ID
     */
    U_Id: string | undefined;
}

export interface UpdatedTask {
    /**
     * 文件名
     */
    name: string;
    /**
     * 文件大小（bit）
     */
    size: string;
    /**
     * 上传文件类型
     */
    type: number;
    /**
     * 单个文件上传状态
     */
    singleState: string;
    /**
     * 进度
     */
    progress: number;
    /**
     * 阿里OSS-sdk 对象
     */
    alioss: ALIOSS;
}

export interface ProgressInfoState {
    updatedTask: UpdatedTask[];
    /**
     * 上传文件失败个数
     */
    errorNum: number;
    /**
     * 上传文件成功个数
     */
    succeedNum: number;
}

export interface PlanType {
    programmeList: Array<any>;
}
export interface currentEditPlansType {
    id: string;
    name: string;
    time: string;
    plan: PlanType[];
    start_end_time: string;
}

type APPCONFIG = ApiRutenType<typeof useApi, "getBasicCustomInfo">;
export interface APPINFO extends APPCONFIG {
    /**
     * 默认为 false
     *
     * Service Workers updatefound 修改为 false
     *
     * Service Workers updated 更新完成为 true
     **/
    newVersion: boolean;
}

/**
 * 全局响应式对象合集
 *
 * 这里用于放置一些大部分组件都有可能依赖的数据
 */
const globalData = {
    /**
     * App 配置
     */
    App: ref<APPINFO>(),
    McAppManage: ref<McAppManage>(),
    uploadProcessRef: ref<{
        /**
         * 关闭上传进度界面
         */
        close: () => void;
    }>(),
    /**
     * 侧边栏是否收起
     */
    menubarFlod: ref(false),
    /**
     * 侧边栏宽度
     */
    menubarFlodWidth: ref("160px"),
    /**
     * 是否登录
     */
    isLogin: ref(true),

    /**
     *
     */
    userInfo: ref<UserInfoState>({
        U_token: "",
        U_tokenExpirationTime: undefined,
        U_avatar: "",
        corpId: "",
        U_name: "",
        U_Id: undefined,
    }),
    /**
     * 用户权限和用户信息
     */
    userPowerInfo: ref(new Map<string, string>()),

    /**
     * 上传媒体进度框是否出现
     */
    isShowProgress: ref(false),
    /**
     * 上传媒体进度信息
     */
    progressInfo: reactive<ProgressInfoState>({
        updatedTask: [],
        errorNum: 0,
        succeedNum: 0,
    }),
    navigatorInfo: navigatorInfo(),
    /**
     * 是否显示发布节目进度
     */
    progressVisible: ref(false),

    /**
     * 企业相关配置
     */
    corpCfg: ref<CorpCfg | undefined>(
        (() => {
            let v: CorpCfg | undefined;
            const _cfgJSON = localStorage.getItem("corpCfg");
            if (_cfgJSON) {
                try {
                    v = JSON.parse(_cfgJSON) as CorpCfg;
                } catch (error) {
                    console.log(error);
                }
            }
            return v;
        })()
    ),
    /**
     * 登出倒计时定时器
     */
    timeoutLogin: ref<number | null>(null),

    /**
     * 当前编辑的计划
     */
    currentEditPlans: reactive<currentEditPlansType>({
        id: "",
        name: "",
        time: "",
        plan: [],
        start_end_time: "",
    }),

    /**
     * 判断媒体文件后缀是否是音频文件
     */
    isMusicReg: ref<RegExp>(),

    ProgramScreen: ref<ProgramScreen>(),
    programPreview: ref<ProgramView[]>(),
};

/**
 * 重设侧边栏宽度
 * @returns
 */
export function resetMenubarWidth() {
    const _w = globalData.menubarFlodWidth;
    if (i18n.global.locale == "zh-CN") {
        _w.value = "160px";
    } else {
        _w.value = "240px";
    }
}

/**
 * ### 全局数据
 *
 * 返回一个响应式对象合集
 *
 */
export function useGlobal(): typeof globalData {
    resetMenubarWidth();
    return globalData;
}

/**
 * 等待全局变量值
 * @param target 目标值
 * @param timeoutCallBack 等待超时回调
 * @param timeout 等待时间
 * @returns
 */
export function awaitGlobalVal(
    target: keyof typeof globalData,
    timeoutCallBack?: () => void,
    timeout = 100
) {
    return new Promise((resolve, reject) => {
        let _w: WatchStopHandle;

        let _t = 0;
        if (isRef(globalData[target])) {
            _w = watch(
                globalData[target],
                (v) => {
                    if (v) {
                        resolve(v);
                        clearTimeout(_t);
                    }
                }
            );
        }

        _t = window.setTimeout(() => {
            _w?.();
            if (typeof timeoutCallBack == "function") {
                timeoutCallBack();
            }
        }, timeout);
    });
}

/**
 * 监听用户信息变化
 */
export function watchUseinfo(): void {
    // 监听用户信息变化
    watch(
        globalData.userInfo,
        (val) => {
            useWebfunny();
            if (val.U_token) {
                useMqtt();
            }
        },
        {
            deep: true,
        }
    );
}

/**
 * 写入监听信息
 */
export function useWebfunny(): void {
    if (process.env.NODE_ENV === "production") {
        const version = `${config.version}.${process.env.VUE_APP_MINI_VERSION}`;
        const userInfo = window.localStorage.getItem("userInfo");
        if (userInfo) {
            const _info = JSON.parse(userInfo) as ApiRutenType<
                typeof useApi,
                "postCorpUserAuthLogin"
            >;
            _info.corpId;
            window.localStorage.wmUserInfo = JSON.stringify({
                userId: _info.userId,
                userTag: _info.corpId,
                projectVersion: version,
            });
        } else {
            const _info = globalData.userInfo.value;
            window.localStorage.wmUserInfo = JSON.stringify({
                userId: _info.U_Id,
                userTag: _info.corpId,
                projectVersion: version,
            });
        }
    }
}

/**
 * 全局静态常量
 */
export const GlobalConst = {
    /**
     * 应用根路由
     */
    APP_PATH_ROOT: "/cloud",
    /**
     * 云发布基础路由
     */
    CLOUD_PATH_BASE: "/standard",
    /**
     * 云系统设置基础路由
     */
    CLOUD_PATH_SYSTEM_CONFIG: "/system",
    /**
     * 行业列表
     * @returns
     */
    CATEGORY_LIST: () => {
        return [
            {
                label: $t("Government and enterprise"),
                value: "政企",
            },
            {
                label: $t("Community property"),
                value: "社区物业",
            },
            {
                label: $t("Advertising media"),
                value: "广告传媒",
            },
            {
                label: $t("Real estate development"),
                value: "房地产开发",
            },
            {
                label: $t("bank"),
                value: "银行",
            },
            {
                label: $t("Medical treatment"),
                value: "医疗",
            },
            {
                label: $t("retail"),
                value: "零售",
            },
            {
                label: $t("tourism"),
                value: "旅游",
            },
            {
                label: $t("Transportation"),
                value: "交通运输",
            },
            {
                label: $t("Non-profit organization"),
                value: "公益组织",
            },
            {
                label: $t("other"),
                value: "其他",
            },
        ];
    },
    /**
     * APP配置缓存key
     */
    APPCFG_CACHE_NAME: "appCfg",
    /**
     * APP语言配置缓存key
     */
    APPLANG_CACHE_NAME: "lang",
};
