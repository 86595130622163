import { ApiRutenType } from "@/@types/Utils";
import useApi from "@/api/apiList";
import { GlobalConst, useGlobal } from "@/global";
import { useRouter } from "vue-router";
import { sleep } from "./sleep";

type APPCONFIG = ApiRutenType<typeof useApi, "getBasicCustomInfo">;
/**
 * 拉取APP客制化配置
 * @returns
 */
export async function getAppConfig() {
    const appLoadingDomId = "appLoading";
    const cacheName = GlobalConst.APPCFG_CACHE_NAME;
    const cache = localStorage.getItem(cacheName);
    const appLoading = () => {
        const appNode = document.getElementById(appLoadingDomId);
        if (appNode) {
            appNode.className = "app-state_load app_load";
        }
    };
    const appShow = (clear = true) => {
        const appNode = document.getElementById(appLoadingDomId);
        if (appNode) {
            appNode.className = "app-state_load app-state_show";
        }

        if (clear) {
            // 大约三帧后清除loading样式
            setTimeout(() => {
                appNode && (appNode.className = ``);
            }, 16 * 3);
        }
    };
    const setCfg = (cfg: APPCONFIG) => {
        useGlobal().App.value = Object.assign(
            {
                newVersion: false
            },
            cfg
        );
        appShow();
        const lang = localStorage.getItem(GlobalConst.APPLANG_CACHE_NAME);
        if (lang) {
            const _lang = document.documentElement.lang;
            if (lang !== _lang) {
                // document.documentElement.lang = lang
            }
        }

        const _link = document.querySelector("head > link[type='image/x-icon']");
        if (_link) {
            _link.setAttribute("href", useGlobal().App.value?.favicon ?? "./favicon.ico");
        }
    };
    try {
        appLoading();
        // await sleep(2000)
        const cfg = await useApi().getBasicCustomInfo({
            req_body_other: {}
        });

        localStorage.setItem(cacheName, JSON.stringify(cfg.data));
        setCfg(cfg.data);
        // await sleep(1000);
    } catch (error) {
        console.error("获取APP配置失败", error);
        appShow(false);
    }

    // 添加本地缓存
    // if (cache) {
    //     setCfg(JSON.parse(cache));
    // } else {
    //     try {
    //         const appLoading = () => {
    //             const appNode = document.getElementById(appLoadingDomId);
    //             if (appNode) {
    //                 appNode.className = "app-state_load";
    //             }
    //         };
    //         appLoading();
    //         await sleep(2000)
    //         const cfg = await useApi().getBasicCustomInfo({
    //             req_body_other: {}
    //         });

    //         localStorage.setItem(cacheName, JSON.stringify(cfg.data));
    //         setCfg(cfg.data);
    //         // await sleep(1000);
    //     } catch (error) {
    //         console.error('获取APP配置失败', error);

    //     }
    // }
}
