import useApi from "@/api/apiList"
import { useGlobal } from "@/global"

/**
* 查询企业配置信息
*/
export function  getCorpCfg() { 
  const corpCfg = useGlobal().corpCfg
  return useApi().getCorpPropOne({
       req_body_other: {}
   }).then((res)=>{
      corpCfg.value = res.data
      localStorage.setItem('corpCfg', JSON.stringify(res.data));
      return true
   })
}