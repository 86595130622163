import { Plugin } from "vue";

type ScrollObj = {
    top: number;
    left: number;
    width: number;
    x: number;
    y: number;
    /**
     * 判断方向， ture 为向下，false 为向上
     */
    bottom: boolean;
    /**
     * 判断方向， ture 为向右边，false 为向左
     */
    right: boolean;
};

export type CallBack = (e: Event, scroll: ScrollObj) => void;

const callBacks = {
    /**
     * 竖向滚动条是否到顶
     */
    top: [] as CallBack[],
    /**
     * 竖向滚动条是否到底
     */
    bottom: [] as CallBack[],
    /**
     * 横向滚动条是否到顶
     */
    left: [] as CallBack[],
    /**
     * 横向滚动条是否到底
     */
    right: [] as CallBack[],
    /**
     * 滚动时响应
     */
    all: [] as CallBack[]
};
/**
 * 监听window.onScroll事件
 */
function obWindowScroll() {
    window.addEventListener("scroll", e => {
        // console.log('obWindowScroll');
        const _target = e.target as Document;

        if (_target.documentElement) {
            const scroll = useScorllOption();

            if (!isNaN(scroll.y)) {
                // 判定竖向滚动是否快要触底
                if (scroll.y >= 0.8) {
                    callBacks.bottom.forEach(cb => {
                        cb(e, scroll);
                    });
                }
                // 判定竖向滚动是否到顶
                if (scroll.y === 0) {
                    callBacks.top.forEach(cb => {
                        cb(e, scroll);
                    });
                }
            }

            if (!isNaN(scroll.x)) {
                // 判定横向滚动是否快要触底
                if (scroll.x >= 0.8) {
                    callBacks.right.forEach(cb => {
                        cb(e, scroll);
                    });
                }

                // 判定横向滚动是否到顶
                if (scroll.x === 0) {
                    callBacks.left.forEach(cb => {
                        cb(e, scroll);
                    });
                }
            }



            // 响应全部滚动
            callBacks.all.forEach(cb => {
                cb(e, scroll);
            });
        }
    });
}

/**
 * 添加监听window滚动事件
 * @param type
 * @param cb
 */
function addScorllAction(type: keyof typeof callBacks, cb: CallBack) {
    callBacks[type].push(cb);
}

const installWindowScorll: Plugin = {
    install(app) {
        document.addEventListener("DOMContentLoaded", obWindowScroll);
    }
};

function useScorllAction(type: keyof typeof callBacks, cb: CallBack): void {
    addScorllAction(type, cb);
}
let oldScroll:ScrollObj
function useScorllOption(): ScrollObj {
    const windowHeight = document.documentElement.clientHeight;
    const windowWidth = document.documentElement.clientWidth;

    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const yNum = scrollTop / (scrollHeight - windowHeight);

    const scrollLeft = document.documentElement.scrollLeft;
    const scrollWidth = document.documentElement.scrollWidth;
    const xNum = scrollLeft / (scrollWidth - windowWidth);

    const scroll: ScrollObj = {
        top: scrollTop,
        left: scrollLeft,
        width: scrollWidth,
        x: xNum,
        y: yNum,
        bottom: false,
        right: false
    };

    if(oldScroll) {
      scroll.bottom = oldScroll.top < scroll.top
      scroll.right = oldScroll.left < scroll.left
    }
    oldScroll = scroll

    return scroll;
}

function useScorllGoTop(): void {
    window.scrollTo({
        top: 0
    });
}

export { useScorllAction, installWindowScorll, useScorllOption, useScorllGoTop };
